import { HTTP } from "../../HTTP/HTTP"
import { getAuthLoginUser } from "../../Services/AuthService/AuthService"
import { ADD_USERS, DELETE_USERS, GET_USERS, UPDATE_USERS } from "../../Shared/Constants/ApiConstant"
import { GET_USER } from "../Types/Type"

export const getAllUsers = () => {
    let loginUser = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.get(`${GET_USERS}`)
        dispatch(getUser(response?.data?.Data))
    }
}

export const addNewUser = (value) => {
    return async (dispatch) => {
        await HTTP.post(ADD_USERS, value)
        dispatch(getAllUsers())
    }
}

export const updateUser = (value) => {
    return async (dispatch) => {
        await HTTP.put(UPDATE_USERS, value).catch((err) => {
        })
        dispatch(getAllUsers())
    }
}

export const deleteUsers = (UserId) => {
    return async (dispatch) => {
        await HTTP.delete(`${DELETE_USERS}?UserId=${UserId}`)
        dispatch(getAllUsers())
    }
}
const getUser = (data) => {
    return {
        type: GET_USER,
        data: data
    }
}