import { HTTP } from "../../HTTP/HTTP";
import { getAuthLoginUser } from "../../Services/AuthService/AuthService";
import { CUSTOMER_INFO_ABOUT_SERVICES } from "../../Shared/Constants/ApiConstant";
import { CUSTOMER_INFO } from "../Types/Type";

export const GetCustomerInfoAboutServices = () => {
    let userData = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.get(`${CUSTOMER_INFO_ABOUT_SERVICES}?CompanyId=${userData.companyId}`);
        dispatch(CustomerInfo(response?.data?.Data))
    }
}

const CustomerInfo = (data) => {
    return {
        type : CUSTOMER_INFO,
        data : data
    }
}