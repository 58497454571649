import React from 'react'
import loginScreen from '../../assets/images/loginScreen.jpg'
import dashboard from '../../assets/images/dashboard.jpg'
import menu from '../../assets/images/menuBar.jpg'
import fullScreenDashBoard from '../../assets/images/fullScreenDashBoard.jpg'
import billRemainingGrid from '../../assets/images/billRemainingGrid.jpg'
import date from '../../assets/images/date.jpg'
import paymentDashBtn from '../../assets/images/paymentDashBtn.jpg'
import paymentForm from '../../assets/images/paymentForm.jpg'
import itemMaster from '../../assets/images/itemMaster.jpg'
import AddBtn from '../../assets/images/AddBtn.jpg'
import itemForm from '../../assets/images/itemForm.jpg'
import itemFilter from '../../assets/images/itemFilter.jpg'
import itemActions from '../../assets/images/itemActions.jpg'
import itemEditForm from '../../assets/images/itemEditForm.jpg'
import header from '../../assets/images/header.jpg'
import companyMaster from '../../assets/images/companyMaster.jpg'
import billMaster from '../../assets/images/billMaster.jpg'
import billMasterHeader from '../../assets/images/billMasterHeader.jpg'
import billMasterBody from '../../assets/images/billMasterBody.jpg'
import billDetails from '../../assets/images/billDetails.jpg'
import filledBillMaster from '../../assets/images/filledBillMaster.jpg'
import filledBillMasterNonGST from '../../assets/images/filledBillMasterNonGST.jpg'
import paymentMaster from '../../assets/images/paymentMaster.jpg'
import paymentFilter from '../../assets/images/paymentFilter.jpg'
import billDetailsPayment from '../../assets/images/billDetailsPayment.jpg'
import paymentHistoryOnSave from '../../assets/images/paymentHistoryOnSave.jpg'
import paymentDetail from '../../assets/images/paymentDetail.jpg'
import paymentBtn from '../../assets/images/paymentBtn.jpg'
import printBill from '../../assets/images/printBill.jpg'
import printBillNonGST from '../../assets/images/printBillNonGST.jpg'
import partyMaster from '../../assets/images/partyMaster.jpg'
import AgrpGroup from '../../assets/images/AgrpGroup.jpg'
import partyName from '../../assets/images/partyName.jpg'
import customerServiceInfo from '../../assets/images/customerServiceInfo.jpg'
import { Col, NavLink, Row } from 'react-bootstrap'
import { imagePopupService } from '../OpenImage/OpenImage'
import { LOGOUT } from '../../Shared/Constants/ApiConstant'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { successPopup } from '../../Shared/Constants/PopupConstant/PopupContant'
import Dashboard from '../../Pages/Dashboard/Dashboard'
import ChangePassword from '../../assets/images/changePassword.jpg'


function Help() {
    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logged out!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                successPopup('You are logged out.')
                setTimeout(() => {
                    window.location.href = '/';
                }, 700);
            }
        })
    }
    return (
        <div className='dashboard_bg'>
            <div className='h-auto w-100'>
                <Row>
                    <h2>Login Screen</h2>
                    <Col md={12} lg={4}>
                        <img src={loginScreen} alt="" height="100%" width='100%' onClick={() => imagePopupService(loginScreen)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the <a href="#" className='link-primary' onClick={() => logout()}>Login Page</a> the user is able to log-in to the system.</li>
                            <li>The User would have to enter their username and password to log-in.</li>
                            <li>The user can change their password from the <a href="#" className='link-primary' onClick={() => logout()}>Change password </a>link.</li>
                            <li>Above function is enabled only when the user is logged-out from the system.</li>
                            <li>Check the functionality from <a href="#" className='link-primary' onClick={() => logout()}>this link</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100'>
                <Row>
                    <h2>Change Password Screen</h2>
                    <Col md={12} lg={4}>
                        <img src={ChangePassword} alt="" height="100%" width='100%' onClick={() => imagePopupService(ChangePassword)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the <a href="#" className='link-primary' onClick={() => logout()}>Login Page</a> the user is able to change password.</li>
                            <li>The user would have to enter old user name and password along with the new password.</li>
                            <li>Above function is enabled only when the user is logged-out from the system.</li>
                            <li>Check the functionality from <a href="#" className='link-primary' onClick={() => logout()}>this link</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Dashboard</h2>
                    <Col md={12} lg={4}>
                        <img src={dashboard} alt="" height="100%" width='100%' onClick={() => imagePopupService(dashboard)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>The <Link className='text-primary' to='/dashboard'>dashboard page </Link>is divided into two parts, the header and the body part.</li>
                            <li>The <a href='#DashboardHeader' className='text-primary'>header </a>consist of startDate and endDate to filter the records.</li>
                            <li>The body is divided into two parts, remaining payments and the customer service info table.</li>
                            <li>Records are displayed in the <a href='#DashboardBody' className='text-primary'>remaining payemnt section</a> to track payments.</li>
                            <li>By default records shows the remaining bill amount of customers last 30 days.</li>
                            <li>Payments can be done in this section as well using the payment button.</li>
                            <li>In the customer service section, the user can track the customer service date so they can call them and remind them to service their car.</li>
                            <li>User can pay their bills from the payment button for each record.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Header</h2>
                    <Col md={12} lg={4}>
                        <img src={header} alt="" height="100%" width='100%' onClick={() => imagePopupService(header)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the header, the user info is shown, company details page can be accessed from the button besides that button.</li>
                            <li>On clicking the logout button, the user would be logged out from the system, and will be redirected to <a href="#" className='link-primary' onClick={() => logout()}>Login Page</a>.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Company Master</h2>
                    <Col md={12} lg={4}>
                        <img src={companyMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(companyMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the company master page, the user can perform the following actions:</li>
                            <li>The user can change the logo of the company on clicking on the company photo.</li>
                            <li>On clicking the save button the changes will be saved.</li>
                            <li>User can change the GST percentage and save them which can be used to calculate the GST bill amount.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Menubar</h2>
                    <Col md={12} lg={4}>
                        <img src={menu} alt="" height="100%" width='100%' onClick={() => imagePopupService(menu)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>The arrow on the top right of the menu bar is useful for expanding and minimizing the sidebar.</li>
                            <li>The Menu section in the left side of the page has the following functionalities:</li>
                            <li><Link className='text-primary' to='/dashboard'>Dashboard </Link>section will display the pending bills in a tabular fashion.</li>
                            <li><Link className='text-primary' to='/items'>Item Master </Link>section will show you the existing items a customer can purchase.</li>
                            <li><Link className='text-primary' to='/bills'>Bill Master </Link>section will enable the user to create new bills or edit older bills as per the requirement.</li>
                            <li><Link className='text-primary' to='/payments'>Payment </Link>section will show you all pending and paid bills.</li>
                            <li><Link className='text-primary' to='/party'>Party Master </Link>section will let you add new party to the system so bills can be directly set to suitable values.</li>
                            <li><Link className='text-primary' to='/help'>Help </Link>sectin will show you how to use the Workshop Management System to your full advantage. </li>
                            <li>The timer is shown below the company logo, so user can track of the auto-log-out timer.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Full screen Dashboard</h2>
                    <Col md={12} lg={4}>
                        <img src={fullScreenDashBoard} alt="" height="100%" width='100%' onClick={() => imagePopupService(fullScreenDashBoard)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>The menu button will close itself on clicking the arrow button.</li>
                            <li>Similarly again clicking the arrow, menu will open itself.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row id='DashboardBody'>
                    <h2>Remaining Bill Dashboard</h2>
                    <Col md={12} lg={4}>
                        <img src={billRemainingGrid} alt="" height="100%" width='100%' onClick={() => imagePopupService(billRemainingGrid)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the body of the <Link className='text-primary' to='/dashboard'>Dashboard </Link>, user can see all the remaining bills with pending payment.</li>
                            <li>These bills can be filtered using the start date and end date filter option.</li>
                            <li>By default records shows the remaining bill amount of customers last 30 days.</li>
                            <li>On clicking the payment button in the body of the page, user can <a href='#PaymentBill' className='text-primary'>submit amount</a> paid by the customer.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='CustomerServiceInfo'>
                    <h2>Customer Service Info</h2>
                    <Col md={12} lg={4}>
                        <img src={customerServiceInfo} alt="" height="100%" width='100%' onClick={() => imagePopupService(customerServiceInfo)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the customer Service Info tab, user can see the customer details who came for service before 10 months.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='DashboardHeader'>
                    <Col md={12} lg={4}>
                        <img src={date} alt="" height="100%" width='100%' onClick={() => imagePopupService(date)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>The <a href='#DashboardHeader' className='text-primary'>header </a>consist of startDate and endDate to filter the records.</li>
                            <li>If user enters only FromDate then the dashbard will shows the record from FromDate to currentDate.</li>
                            <li>If user enters only ToDate then the dashbard will shows the record till ToDate.</li>
                            <li>If user enters only FromDate and ToDate then the dashbard will shows the record between FromDate to ToDate.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <Col md={12} lg={4}>
                        <img src={paymentDashBtn} alt="" height="100%" width='100%' onClick={() => imagePopupService(paymentDashBtn)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the <Link className='text-primary' to='/dashboard'>Dashboard </Link>section, user can see the remaining amount left in each bill.</li>
                            <li>On clicking the <a href="#PaymentBill" className='text-primary'>Payment button</a>, user can add payment from the customer.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='PaymentBill'>
                    <h2>Payment View</h2>
                    <Col md={12} lg={4}>
                        <img src={paymentForm} alt="" height="100%" width='100%' onClick={() => imagePopupService(paymentForm)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>User can add payment details if the customer pays half or full payment.</li>
                            <li>The Auto adjust checkbox will fill the remaining amount in the adjust amount field.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Item Master</h2>
                    <Col md={12} lg={4}>
                        <img src={itemMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(itemMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>In the item master section of the application, user can perform following actions: </li>
                            <li>Add a <a href="#NewItem" className='text-primary'>new item</a> to the system</li>
                            <li>User can <a href="#FilterItem" className='text-primary'>filter items</a> on the basis of Name, Rate and code.</li>
                            <li>On clicking the <a href="#AddItem" className='text-primary'>add button</a> user can add a new item in the inventory.</li>
                            <li>On clicking the <a href="#EditItem" className='text-primary'>edit button</a> user can edit the item of choice from the grid.</li>
                            <li>On clicking the delete button, user is asked for confirmation, and then the selected item is deleted from the inventory.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='NewItem'>
                    <Col md={12} lg={4}>
                        <img src={AddBtn} alt="" height="100%" width='100%' onClick={() => imagePopupService(AddBtn)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>On clicking the <a href="#AddItem" className='text-primary'>add button</a> user can add a new item in the inventory.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='AddItem'>
                    <Col md={12} lg={4}>
                        <img src={itemForm} alt="" height="100%" width='100%' onClick={() => imagePopupService(itemForm)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>The user has to fill the required fields in the form to add the item.</li>
                            <li>IsActive will by default be checked, but user can disable it later.</li>
                            <li>If the item entered is in form of labour, on checking the labour check, users cannot increase the count of the iteml.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='FilterItem'>
                    <Col md={12} lg={4}>
                        <img src={itemFilter} alt="" height="100%" width='100%' onClick={() => imagePopupService(itemFilter)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>User can filter items based on the inputs of name, rate and code.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <Col md={12} lg={4}>
                        <img src={itemActions} alt="" height="100%" width='100%' onClick={() => imagePopupService(itemActions)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>On <a href="#editItems" className='text-primary'> Edit button</a> click a popup form will be open based on the item details.</li>
                            <li>On click on Delete button user is asked for confirmation.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id="editItems">
                    <Col md={12} lg={4}>
                        <img src={itemEditForm} alt="" height="100%" width='100%' onClick={() => imagePopupService(itemEditForm)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>Fields are filled in the form based on the item.</li>
                            <li>User can edit required fields and submit.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Bill Master</h2>
                    <Col md={12} lg={4}>
                        <img src={billMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(billMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is the previw of Bill Master.</li>
                            <li>This is devided into <a href=""></a>header and body.</li>
                            <li>The header section consist of <a href="#billDetails" className='text-primary'> Bill detail</a> poup and filter options.</li>
                            <li>You can reset the form using reset button.</li>
                            <li>The body section consist of items to be added in the bill.</li>
                            <li>You can add new items directly by writing in the item field.</li>
                            <li>The rate of each item can be updated from the rate field.</li>
                            <li>The final bill to be made can be GST and non-GST as per requirement.</li>
                        </ul>
                    </Col>
                </Row>
            </div>

            <div className='h-auto w-100 mt-4'>
                <Row id='billDetails'>
                    <Col md={12} lg={4}>
                        <img src={billDetails} alt="" height="100%" width='100%' onClick={() => imagePopupService(billDetails)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>On clicking the select option the items in the bill are filled in the <a href="#billBody" className='text-primary'>body section.</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='billBodyGST'>
                    <Col md={12} lg={4}>
                        <img src={filledBillMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(filledBillMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of filled Bill master of GST bill.</li>
                            <li>New items can be added, edited or deleted.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='billBodyNonGST'>
                    <Col md={12} lg={4}>
                        <img src={filledBillMasterNonGST} alt="" height="100%" width='100%' onClick={() => imagePopupService(filledBillMasterNonGST)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of filled Bill master for non GST bill.</li>
                            <li>New items can be added, edited or deleted.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>History</h2>
                    <Col md={12} lg={4}>
                        <img src={paymentMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(paymentMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of payment section.</li>
                            <li>This is devided into header and body.</li>
                            <li>In the header section items can be filtered by the given field.</li>
                            <li>From the header on clicking <a href="#paymentHistoryDetails" className='text-primary'>save button </a>item history will be shown.</li>
                            <li>On clicking clear button all details from the body are cleared.</li>
                        </ul>
                    </Col>
                </Row>
            </div>

            <div className='h-auto w-100 mt-4'>
                <Row id='paymentHistoryDetails'>
                    <Col md={12} lg={4}>
                        <img src={paymentHistoryOnSave} alt="" height="100%" width='100%' onClick={() => imagePopupService(paymentHistoryOnSave)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of payment history on clicking save button.</li>
                            <li>In the body section all the bill master details are provided.</li>
                            <li>Individual <a href="#paymentBillDetails" className='text-primary'>bill details</a> and <a href="#paymentDetails" className='text-primary'>payment detail</a> can be seen from the popup.</li>
                            <li>Bill can also be printed via click of <a href="#print" className='text-primary'>print button.</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>

            <div className='h-auto w-100 mt-4'>
                <Row id='paymentBillDetails'>
                    <Col md={12} lg={4}>
                        <img src={billDetailsPayment} alt="" height="100%" width='100%' onClick={() => imagePopupService(billDetailsPayment)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of Bill detail payment.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='paymentDetails'>
                    <Col md={12} lg={4}>
                        <img src={paymentDetail} alt="" height="100%" width='100%' onClick={() => imagePopupService(paymentDetail)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is priview of payment details.</li>
                        </ul>
                    </Col>
                </Row>
            </div>

            <div className='h-auto w-100 mt-4'>
                <Row id='print'>
                    <Col md={12} lg={4}>
                        <img src={printBill} alt="" height="100%" width='100%' onClick={() => imagePopupService(printBill)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of GST Bill print.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4'>
                <Row id='print'>
                    <Col md={12} lg={4}>
                        <img src={printBillNonGST} alt="" height="100%" width='100%' onClick={() => imagePopupService(printBillNonGST)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of Non GST Bill print.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='h-auto w-100 mt-4'>
                <Row>
                    <h2>Party Master</h2>
                    <Col md={12} lg={4}>
                        <img src={partyMaster} alt="" height="100%" width='100%' onClick={() => imagePopupService(partyMaster)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of Party Master.</li>
                            <li>User can add the new party using this form.</li>
                            <li>User can edit and delete the party with the same form by clicking on particular button.</li>
                            <li>User can fill existing party detail from the <a href="#partyName" className='text-primary'>3 dot</a> button.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <div className='h-auto w-100 mt-4 '>
                <Row id='partyName' >
                    <Col md={12} lg={4} className='mb-4'>
                        <img src={partyName} alt="" height="100%" width='100%' onClick={() => imagePopupService(partyName)} />
                    </Col>
                    <Col md={12} lg={8}>
                        <ul>
                            <li>This is preview of party name details.</li>
                            <li>On click of party name filds are filled with party details.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <hr />
        </div>
    )
}

export default Help
