import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { getAllCompaniesDetails, updateSecurityKeyCompany } from '../../Redux/Actions/GetAllCompanyAction';

function SecurityKey() {
    const { register, handleSubmit, formState: { errors }, reset, control, setValue } = useForm();
    let [optionList, setoptionList] = useState([])
    let [selectedOptions, setSelectedOptions] = useState();
    const dispatch = useDispatch();
    const allCompany = useSelector(state => state.allCompany.allCompany)
    // let [isCompanyId, setisCompanyId] = useState(false)



    useEffect(() => {
        dispatch(getAllCompaniesDetails())
    }, [])

    useEffect(() => {

        setoptionList([...allCompany.map(x => {
            return { value: x.CompanyId, label: x.Name }
        })])
    }, [allCompany])


    const onSubmit = (data) => {
        data.CompanyId = data.selectInput?.value
        dispatch(updateSecurityKeyCompany(data))
        reset()
        reset({ selectInput: null })
    }

    return (
        <>
            <div className='dashboard_bg'>
                <Row>
                    <Col xs={12}>
                        <h2>Security Key</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center align-items-center' >
                    <Col xs={12} md={12} lg={6} >
                        <form className='shadow-lg w-100 px-4 pt-3 pb-4 bg-white'>
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <label className='form_label'>Company Name:</label>


                                    <Controller
                                        name="selectInput"
                                        control={control}
                                        rules={{ required: 'Company selection is required.' }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={optionList}
                                                name='CompanyId'
                                            />
                                        )}
                                    />
                                    {errors.selectInput && (
                                        <span className="error_message">{errors.selectInput.message}</span>
                                    )}
                                    {/* {errors.CompanyId  &&<span className='error_message'>Company selection is required.</span>} */}
                                </Col>
                                <Col sm={12} md={12} lg={12}>
                                    <label className='form_label'>Access Key:</label>
                                    <input type="password" placeholder='dd/mm/yyyy' className='form_input' {...register("SecurityKey", { required: true })} />
                                    {errors.SecurityKey && <span className='error_message'>SecurityKey is required.</span>}
                                </Col>
                                <Col sm={12} md={12} lg={12}>
                                    <label className='form_label remove-space w-auto invisible'>'</label>
                                    <button className='form_input btn btn-success w-auto px-4' onClick={handleSubmit(onSubmit)}>
                                        Save
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SecurityKey