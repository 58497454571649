import axios from "axios";
import { HTTP, headers } from "../../HTTP/HTTP";
import { getAuthLoginUser, unAuthorizedLogout } from "../../Services/AuthService/AuthService";
import { ADD_PAYMENT, ApiHttp, DELETE_BILLPAYMENT, GET_PAYMENT_DETAIL } from "../../Shared/Constants/ApiConstant";
import { PAYMENT_DETAIL } from "../Types/Type";
import { errorPopup } from "../../Shared/Constants/PopupConstant/PopupContant";
import { loaderService } from "../../Components/Loader/Loader";
import { getBillMasterDetails } from "./BillMasterDetailsAction";
import { GetAllRemainingBillDetails } from "./RemainingAmountAction";

export const addPayment= (data, callbackData) => {
    let loginDetail = getAuthLoginUser();
    const payload = {
        CompanyId: loginDetail.companyId,
        AcYear: loginDetail.acYear,
        BillNo: data.BillNo,
        SeqNo: 0,
        UserId: loginDetail.userId,
        PaymentDate: data.PaymentDate,
        PaymentAmount: Number(data.PaymentAmount),
        AdjustAmount: Number(data.AdjustAmount),
        Remark: data.Remark
    };
    return async (dispatch) => {
        loaderService(true)
        return axios.post(`${ApiHttp}${ADD_PAYMENT}`, payload , headers).then((res) => {
            if(res.data.StatusCode == 400){
                errorPopup(res.data.Message)
            }
            loaderService(false)
            dispatch(getpayment([]))
            dispatch(getBillMasterDetails(getAuthLoginUser()))
            dispatch(GetAllRemainingBillDetails())

            return res;
        }).catch((error) => {
            unAuthorizedLogout(error);
            return error
        })
    }
}

export const updatePayment= (data, callbackData) => {
    let loginDetail = getAuthLoginUser();
    const payload = {
        CompanyId: loginDetail.companyId,
        AcYear: loginDetail.acYear,
        BillNo: data.BillNo,
        SeqNo: data.SeqNo,
        UserId: loginDetail.userId,
        PaymentDate: data.PaymentDate,
        PaymentAmount: Number(data.PaymentAmount),
        AdjustAmount: Number(data.AdjustAmount),
        Remark: data.Remark
    };
    return async (dispatch) => {
        loaderService(true)
        return axios.post(`${ApiHttp}${ADD_PAYMENT}`, payload , headers).then((res) => {
            if(res.data.StatusCode == 400){
                errorPopup(res.data.Message)
            }
            loaderService(false)
            dispatch(getPaymentDetail(data))
            // dispatch(getpayment([]))
            dispatch(getBillMasterDetails(getAuthLoginUser()))
            dispatch(GetAllRemainingBillDetails())

            return res;
        }).catch((error) => {
            unAuthorizedLogout(error);
            return error
        })
    }
}
 export const deleteBillPayment = (data) =>{
    let loginDetail = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.delete(`${DELETE_BILLPAYMENT}?CompanyId=${data.CompanyId}&AcYear=${data.AcYear}&BillNo=${data.BillNo}&SeqNo=${data.SeqNo}`)
        dispatch(getPaymentDetail(data))
        dispatch(getBillMasterDetails(getAuthLoginUser()))
    }
 }

export const getPaymentDetail = (data) =>{
    let loginDetail = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.get(`${GET_PAYMENT_DETAIL}?CompanyId=${data.CompanyId}&AcYear=${data.AcYear}&BillNo=${data.BillNo}`)
        dispatch(getpayment(response?.data?.Data))
    }

}
const getpayment = (data) => {
    return {
        type : PAYMENT_DETAIL,
        data : data
    }
}
