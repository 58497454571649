import React from 'react'
import {MdSignalWifiConnectedNoInternet3} from 'react-icons/md'

function InternetConnection() {
  return (
    <>
        <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'100vh'}}>
            <MdSignalWifiConnectedNoInternet3 size={50} />
            <h1>You are offline.</h1>
            <p className='fs-6'>Please check your internet connection.</p>
        </div>
    </>
  )
}

export default InternetConnection