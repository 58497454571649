import axios from "axios";
import { headers } from "../../HTTP/HTTP";
import { getAuthLoginUser } from "../../Services/AuthService/AuthService";
import { ApiHttp, BILL_DOWNLOAD_PDF, BILL_PRINT_PDF } from "../../Shared/Constants/ApiConstant";

export const downloadBillPDF = async (billNo, existData) => {
  let loginUser = getAuthLoginUser();
  try {
    const payload = {
      BillNo: billNo,
      CompanyId: loginUser.companyId,
      AcYear: loginUser.acYear
    }

    await axios.post(`${ApiHttp}${BILL_DOWNLOAD_PDF}` , payload, {
      headers: {
        'Authorization': headers.headers.Authorization,
      },
      responseType: 'blob',
    }).then(res => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        if(existData){
          link.download = existData.VehicleNo +'.pdf';
        }else{
          link.download = 'Bill.pdf';
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    })
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};
