import React, { useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import Resizer from "react-image-file-resizer";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCompanies, deleteCompanies, getAllCompanies, updateCompany } from '../../Redux/Actions/CompanyMasterAction';
import { confirmationDeletePopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import { toBase64 } from '../../Shared/Constants/Constant';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import {HiPencilSquare} from 'react-icons/hi2'

/*eslint eqeqeq: "off"*/
function CompanyMaster() {
  const AllCompanies = useSelector(state => state.company.company);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [isAdd, setisAdd] = useState(true)
  const [show, setshow] = useState(false)
  let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  let [ValidEmail, setValidEmail] = useState(false)
  let [ValidImage, setValidImage] = useState(true)
  let imageRef = useRef();


  // const handleShow = () => {
  //   setshow(true)
  // }
  const handleClose = () => {
    setshow(false)
    setisAdd(true)
    reset()
  }
  const onSubmit = (data) => {
    // if(data.file.length == 0){
    //   data.file = null
    // }else{
    //   data.file = data.file[0]
    // }
    // if(ValidImage && data.file[0]){
    //   Resizer.imageFileResizer(
    //     data.file[0],
    //     170,
    //     170,
    //     "PNG", // Output format (you can change it as needed)
    //     100, // Quality (you can change it as needed)
    //     0,
    //     (resizedFile) => {
    //       data.file = resizedFile
    //       dispatch(updateCompany(data))
    //     },
    //     "file")
    //   reset()
    //   handleClose()
    // }else{
    //   dispatch(updateCompany(data))
    // }
    if(ValidImage ){
        if(data.file[0]){
          data.file = data.file[0]
        }
        dispatch(updateCompany(data))
      reset()
      handleClose()
    }
  };

  useEffect(() => {
    dispatch(getAllCompanies())
  }, [])
  useEffect(() => {
    setValue("Name", AllCompanies[0]?.Name)
    setValue("Add1", AllCompanies[0]?.Add1)
    setValue("Add2", AllCompanies[0]?.Add2)
    setValue("Add3", AllCompanies[0]?.Add3)
    setValue("EmailId", AllCompanies[0]?.EmailId)
    setValue("Gstno", AllCompanies[0]?.Gstno)
    setValue("CGST", AllCompanies[0]?.Cgst)
    setValue("SGST", AllCompanies[0]?.Sgst)
    setValue("MobileNo", AllCompanies[0]?.MobileNo)
  }, [AllCompanies])


  // const deleteCompany = (CompanyId) => {
  //   confirmationDeletePopup().then((result) => {
  //     if (result.isConfirmed) {
  //       dispatch(deleteCompanies(CompanyId))
  //       return true
  //     }
  //   })
  // }
  // const editCompany = (CompanyId) => {
  //   setisAdd(false)
  //   handleShow();
  //   const editData = AllCompanies.find(x => x.CompanyId === CompanyId)
  //   for (let key in editData) {
  //     setValue(key, editData[key]);
  //   }
  // }

  // const columns = [
  //   {
  //     name: 'Company Name',
  //     selector: row => row.CompanyName,
  //     sortable: true
  //   },
  //   {
  //     name: 'Company Address',
  //     selector: row => row.CompanyAddress,
  //     sortable: true
  //   },
  //   {
  //     name: 'FirstName(Contact Person)',
  //     selector: row => row.ContactPersonFirstName,
  //     sortable: true
  //   },
  //   {
  //     name: 'LastName (Contact Person)',
  //     selector: row => row.ContactPersonLastName,
  //     sortable: true
  //   },
  //   {
  //     name: 'Office Contact No.',
  //     selector: row => row.ContactNoOffice,
  //     sortable: true
  //   },
  //   {
  //     name: 'Personal Contact No.',
  //     selector: row => row.ContactNoPersonal,
  //     sortable: true
  //   },
  //   {
  //     name: 'IsActive',
  //     selector: row => row.IsActive ? 'Active' : 'Inactive',
  //     sortable: true
  //   },
  //   {
  //     name: 'Actions',
  //     selector: (row) => <div className='white_space_nowrap' style={{ width: '550px' }}>
  //       <button className='btn btn-warning py-1' onClick={() => editCompany(row.CompanyId)}>EDIT</button>
  //       <button className='btn btn-danger py-1 ms-2' onClick={() => deleteCompany(row.CompanyId)}>DELETE</button>
  //     </div>,
  //     sortable: true
  //   },
  // ];

  const getImage = async (e) => {
    if(e.target.files[0]){
        if(e.target.files[0].size <= 102400){
          imageRef.current.src = await toBase64(e.target.files[0]) ;
        imageRef.current.style.display = 'block';
        ValidImage = true;
        setValidImage(ValidImage);
        }
        else{
          ValidImage = false;
        setValidImage(ValidImage);
        }
        
    }
}

const resetImage = () => {
    imageRef.current.style.display = 'none';
    imageRef.current.src = '' ;
}
  const setEmail = (Email) => {
    if (Email && Email != '' && !emailRegex.test(Email)) {
      ValidEmail = true;
      setValidEmail(ValidEmail)
    }
    else {
      ValidEmail = false;
      setValidEmail(ValidEmail)
    }
  }
  return (
    <>
      <div className='dashboard_bg'>
        <Row className='m-0 w-100'>
          <Col xs={6} className='p-0'>
            <h2 className='page_heading'>Company Master</h2>
          </Col>
          {/* <Col xs={6} className='p-0 text-end'>
            <button className='button_dark py-2 px-4' onClick={handleShow}>Add +</button>
          </Col> */}
        </Row>

        <Row className='d-flex justify-content-center align-items-center m-0' >
          <Col xs={12} md={12} lg={7} >
            <form onSubmit={handleSubmit(onSubmit)} className='shadow-lg w-100 px-4 pt-3 pb-4 bg-white'>
              <Row>
              <Col lg={12}>
                {
                   //AllCompanies[0]?.Logo && 
                  <div className='position-relative d-flex justify-content-center' >
                    {
                      AllCompanies[0]?.Logo && <img src={'data:image/png;base64,'+ AllCompanies[0]?.Logo} alt="" style={{height: AllCompanies[0]?.Logo ? '' : '100px' , width: AllCompanies[0]?.Logo ? '' : '100px'}} ref={imageRef} className='company_logo_img' />
                    }
                    
                    <div className='company_logo_img_overlay'>
                      <label htmlFor='file'>
                        <HiPencilSquare color='white' size={30} className='cursor_pointer' />
                      </label>
                      <input type="file" className='mt-2 d-none' id='file' autoComplete="on" {...register("file")} onChangeCapture={getImage} />
                    </div>
                  </div>
                }
                {ValidImage ? '' :<span className='error_message'>Image size is not more than 100kb.</span>}
                </Col>

                <Col lg={12}>
                  <label className='form_label'>Company Name:</label>
                  <input type="text" className='form_input' {...register("Name", { required: true })} />
                  {errors.Name && <span className='error_message'>Company Name is required</span>}
                </Col>

                <Col lg={4}>
                  <label className='form_label'>Company Adress1:</label>
                  <input type="text" className='form_input' autoComplete="on" {...register("Add1", { required: true })} />
                  {errors.Add1 && <span className='error_message'>Company Adress1 is required</span>}
                </Col>
                <Col lg={4}>
                  <label className='form_label'>Company Adress2:</label>
                  <input type="text" className='form_input' autoComplete="on" {...register("Add2", { required: true })} />
                  {errors.Add2 && <span className='error_message'>Company Adress2 is required</span>}
                </Col>
                <Col lg={4}>
                  <label className='form_label'>Company Adress3:</label>
                  <input type="text" className='form_input' autoComplete="on" {...register("Add3", { required: true })} />
                  {errors.Add3 && <span className='error_message'>Company Adress3 is required</span>}
                </Col>
                <Col sm={12} lg={6}>
                  <label className='form_label'>GST No:</label>
                  <input type="text" className='party_form_input' autoComplete="on" {...register("Gstno")} />
                  {errors.GSTNo && <span className='error_message'>GSTNo is required</span>}
                </Col>
                <Col sm={12} lg={6}>
                  <label className='form_label'>Email:</label>
                  <input type="email" className='party_form_input' autoComplete="on" {...register("EmailId", { required: true })} onChangeCapture={(e) => setEmail(e.target.value)} />
                  {
                    ValidEmail ? <p className='error_message m-0 p-0'>Email must be Valid</p> : <></>
                  }
                  {errors.EmailId && <span className='error_message'>EmailId is required</span>}
                </Col>
                <Col sm={12} lg={6}>
                  <label className='form_label'>Mobile:</label>
                  <input type="number" className='party_form_input' autoComplete="on" {...register("MobileNo", { required: true })} />
                  {errors.MobileNo && <span className='error_message'>Mobile number is required.</span>}
                </Col>
                <Col sm={6} lg={3}>
                <label className='form_label'>CGST:</label>
                <input type="number" className='party_form_input' autoComplete="on" {...register("CGST", { required: true })} />
                {errors.CGST && <span className='error_message'>CGST percentage is required.</span>}
                </Col>
                <Col sm={6} lg={3}>
                <label className='form_label'>SGST:</label>
                <input type="number" className='party_form_input' autoComplete="on" {...register("SGST", { required: true })} />
                {errors.SGST && <span className='error_message'>SGST percentage is required.</span>}
                </Col>
                {/* <Col lg={12}>
                  <label className='form_label' >Item Image:</label>
                  <label htmlFor='file' className='selectItemImage' onClick={resetImage}><AiOutlineCloudDownload size={25} className='me-2' />Select Image</label>
                  <input type="file" className='mt-2 d-none' id='file' autoComplete="on" {...register("file")} onChangeCapture={getImage} />
                  {ValidImage ? '' :<span className='error_message'>Image size is not more than 100kb.</span>}
                </Col> */}
                {/* <Col lg={12}>
                  <img src='' style={{display:'none'}} className='my-4' ref={imageRef} alt="" width='100%' height='100%'   />
                </Col> */}
                <Col lg={12}>
                  <button className='btn btn-success mt-4 px-5 py-2' onClick={handleSubmit(onSubmit)}>Save</button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>

        {/* <CustomDataTable columns={columns} data={AllCompanies} /> */}
      </div>

    </>
  )
}

export default CompanyMaster