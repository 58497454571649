import React, { useEffect } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import './Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCountDetail } from '../../Redux/Actions/GetTotalOrderCountAction';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment';

function Cards(props) {
    const orderCount = useSelector(state => state.orderCount.orderCount)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCountDetail())
    }, [])

    const NavLinkData = (dataName , parameter) => {
        if(orderCount && orderCount[dataName] && orderCount[dataName] != 0){
            return <NavLink to={`/confirmOrder/${props.Dates?.FromDate ?? moment().format('YYYY-MM-DD')}/${props.Dates?.ToDate ?? moment().format('YYYY-MM-DD')}/${parameter ?? ''}`}>
                        {orderCount[dataName]}
                    </NavLink>
        }else{
            return <Link>0</Link>
        }
    }

    return (
        <>
            <Row className='g-4 h-100'>
                <Col sm={12} md={6} lg={6}>
                    <Card className='dash_card_1 h-100'>
                        <Card.Body>
                            <Card.Text className='h-100 align-items-center justify-content-center d-flex flex-column text-center'>
                                <span className='fs-50 Nav'>
                                    {NavLinkData('TotalOrders')}
                                </span>
                                <span>Total Orders</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Card className='dash_card_2 h-100'>
                        <Card.Body>
                            <Card.Text className='h-100 align-items-center justify-content-center d-flex flex-column text-center'>
                                <span className='fs-50 Nav'>
                                    {NavLinkData('ConfirmedOrders' ,'IsOrderConfirmed' )}
                                </span>
                                <span>Confirmed Orders</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Card className='dash_card_3 h-100'>
                        <Card.Body>
                            <Card.Text className='h-100 align-items-center justify-content-center d-flex flex-column text-center'>
                                <span className='fs-50 Nav'>
                                    {NavLinkData('OrderDispatched' ,'IsOrderDispatched' )}
                                </span>
                                <span>Dispatched Orders</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <Card className='dash_card_4 h-100'>
                        <Card.Body>
                            <Card.Text className='h-100 align-items-center justify-content-center d-flex flex-column text-center'>
                                <span className='fs-50 Nav'>
                                    {NavLinkData('OrderReceived' ,'IsOrderReceived' )}
                                </span>
                                <span>Received Orders</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Cards