import React, { useEffect, useState } from 'react';
import './OpenImage.css'
import {AiOutlineClose} from 'react-icons/ai'

export const imagePopupService = (value) => {
  if(value){
    document.documentElement.style.setProperty('--image-popup-display', 'block')
    document.querySelector('.main_div img').src = value;
  }else{
      document.documentElement.style.setProperty('--image-popup-display', 'none')
      document.querySelector('.main_div img').src = '';
  }
}
function OpenImage() {
  return (
    <>
            <div className='main_div text-center' >
                <div className='float-end p-4'>
                    <AiOutlineClose size={30} color='white' className='cursor_pointer' onClick={() => imagePopupService(false)} />
                </div>
                <img alt=""  />
            </div>
    </>
  )
}

export default OpenImage