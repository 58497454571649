import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import '../PartyMaster/PartyMaster.css'
import { getPartyCodeAction } from '../../Redux/Actions/GetPartyCodeAction';
import { addNewParty, deleteParty, getAllPartyDetail } from '../../Redux/Actions/PartyMasterAction';
import { confirmationDeletePopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import { getAgrDetail } from '../../Redux/Actions/AGRmasterAction';
import DataTable from 'react-data-table-component';
import { tableCustomStyles2 } from '../../Shared/Constants/Constant';
import { Button, Col, Modal, Row } from 'react-bootstrap';

function PartyMaster() {
  const [show, setshow] = useState(false)
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const party = useSelector(state => state.partyDetail.partyDetail)
  const partyCode = useSelector(state => state.partyCode.partyCode)
  const AllAgrGroup = useSelector(state => state.agrDetail.agrDetail)
  let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  let [ValidEmail, setValidEmail] = useState(false)
  let [AccGroup, setAccGroup] = useState()
  useEffect(() => {
    dispatch(getPartyCodeAction())
}, [])

const handleSelectChange = (e) => {
    AccGroup = e.target.value;
    setAccGroup(AccGroup)
    dispatch(getAllPartyDetail(e.target.value));
    dispatch(getPartyCodeAction())
    setValue("PartyCode", partyCode.PartyCode)
}

const FillPartyForm = (row) => {
    setPartyInForm(row)
}
const column = [
    {
        name: 'PartyName',
        selector: (row, i) => <div className='w-100 h-100 d-flex align-items-center px-2 cursor_pointer' onClick={() => FillPartyForm(row)}>
            {row?.PartyName}
        </div>
        ,
        sortable: true
    }
];
const handleClose = () => {
    setshow(false);
}
const popupOpen = () => {
    setshow(true);
    // setTimeout(() => {
    //     if (elementRef?.current) {
    //         let ele = elementRef?.current;
    //         ele.parentElement.style.width = "100%"
    //         ele.parentElement.style.height = "100%"
    //         // ele.parentElement.parentElement.style.padding = "0"
    //     }
    // }, 100);
}
const setPartyInForm = (row) => {
    for (let key in row) {
        setValue(key, row[key])
    }
    handleClose();
}
const setEmail = (Email) =>{
    if(Email && Email != '' && !emailRegex.test(Email)){
        ValidEmail = true;
        setValidEmail(ValidEmail)
    }
    else{
        ValidEmail = false;
        setValidEmail(ValidEmail)
    }
}
const onSubmit = (data) => {
    if(data.Email){
        if(data.Email && data.Email != '' && !emailRegex.test(data.Email)){
            ValidEmail = true;
            setValidEmail(ValidEmail)
        }else{
            ValidEmail = false;
            setValidEmail(ValidEmail)
            dispatch(addNewParty(data , AccGroup))
            for (let key in data) {
                setValue(key, '')
            }
        }
    }
    else{
        dispatch(addNewParty(data , AccGroup))
            for (let key in data) {
                setValue(key, '')
            }
    }
}
const onDelete = (data) => {
    confirmationDeletePopup().then((result) => {
        if (result.isConfirmed) {
            dispatch(deleteParty(data , AccGroup));
            dispatch(getPartyCodeAction())
            return true
        }
    })
    for (let key in data) {
        setValue(key, '')
    }
}

  return (
    <>
    <div className='dashboard_bg'>
        <Row>
            <Col xs={12}>
                <h2>Party Master</h2>
            </Col>
        </Row>

        <Row className='d-flex justify-content-center align-items-center' >
            <Col xs={12} md={12} lg={7} >
                <form className='shadow-lg w-100 px-4 pt-3 pb-4 bg-white'>
                    <Row>
                        <Col sm={12} md={12} lg={7}>
                            <label className='form_label'>Account Group:</label>
                            <select name="CompanyId" className='party_form_input' defaultValue={''} {...register("Agrcode", { required: true })} onChange={handleSelectChange}>
                                <option value='' disabled>--Account Group--</option>
                                {
                                    AllAgrGroup?.map((x, i) => {
                                        return <option key={i} value={x.Agrcode}>{x.Agrname}</option>
                                    })
                                }
                            </select>
                            {errors.Agrcode && <span className='error_message'>Account group is required.</span>}
                        </Col>


                        <Col xs={9} sm={10} lg={6}>
                            <label className='form_label'>Party Code:</label>
                            <input type="text" className='party_form_input bg-light' autoComplete="on" {...register("PartyCode", { required: true })} readOnly />
                        </Col>
                        <div className='party_form_input w-auto align-self-end px-2 shadow-sm ms-3' style={{ height: 'fit-content', cursor: 'pointer' }} onClick={() => popupOpen()}>
                            ...
                        </div>
                        {errors.PartyCode && <span className='error_message'>Party code is required.</span>}


                        <Col sm={12} lg={12} className='mt-2'>
                            <hr style={{ border: '1.5px solid gray' }} />
                        </Col>
                        <Col sm={12} lg={12}>
                            <label className='form_label'>Party Name:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("PartyName", { required: true })} />
                            {errors.PartyName && <span className='error_message'>Party name is required.</span>}

                        </Col>
                        <Col sm={12} lg={12}>
                            <label className='form_label'>Reference Name:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("ReferenceName")} />
                        </Col>

                        <Col sm={12} lg={4}>
                            <label className='form_label'>Adress 1:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Add1")} />
                        </Col>
                        <Col sm={12} lg={4}>
                            <label className='form_label'>Adress 2:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Add2")} />
                        </Col>
                        <Col sm={12} lg={4}>
                            <label className='form_label'>Adress 3:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Add3")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>City:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("City")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>State:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("State")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>Mobile:</label>
                            <input type="number" className='party_form_input' autoComplete="on" {...register("Mobile",{ required: true })} />
                            {errors.Mobile && <span className='error_message'>Mobile number is required.</span>}
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>Phone:</label>
                            <input type="number" className='party_form_input' autoComplete="on" {...register("Phone")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>Fax:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Fax")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>Email:</label>
                            <input type="email" className='party_form_input' autoComplete="on" {...register("Email")} onChange={(e) => setEmail(e.target.value)} />
                            {
                                    ValidEmail ? <p className='error_message m-0 p-0'>Party Email must be Valid</p> : <></>
                            }
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>PAN No:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("PanNo")} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <label className='form_label'>GST No:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Gstno")} />
                        </Col>
                        <Col sm={12} lg={12}>
                            <label className='form_label'>Remark:</label>
                            <input type="text" className='party_form_input' autoComplete="on" {...register("Remark")} />
                        </Col>
                        <Col sm={12} lg={12}>
                            <button className='btn btn-success mt-4 px-4 py-2 ' onClick={handleSubmit(onSubmit)}>Save</button>
                            <button className='btn btn-danger mt-4 ms-3 px-4 py-2 ' onClick={handleSubmit(onDelete)} >Delete</button>
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>

        <Modal size='md' show={show} fullscreen='md-down' onHide={handleClose} backdrop="static" >
            <Modal.Header className='border-0 p-0 rounded'>
            </Modal.Header>
            <Modal.Body className=' p-0'>
                <DataTable columns={column}
                    data={party} fixedHeader
                    fixedHeaderScrollHeight="75vh"
                    customStyles={tableCustomStyles2} />

            </Modal.Body>
            <Modal.Footer className='py-1'>
                <Button variant="outline-danger" className="fs-12px" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    </div>
</>
  )
}

export default PartyMaster