import { HTTP } from "../../HTTP/HTTP";
import { getAuthLoginUser } from "../../Services/AuthService/AuthService";
import { REMAINING_AMOUNT_DETAILS } from "../../Shared/Constants/ApiConstant";
import { REMAINING_AMOUNT } from "../Types/Type";

export const GetAllRemainingBillDetails = (data) => {
    let userData = getAuthLoginUser();
    return async (dispatch) => {
        let response;
        if(data){

            response = await HTTP.get(`${REMAINING_AMOUNT_DETAILS}?CompanyId=${userData.companyId}&AcYear=${userData.acYear}&FromDate=${data.FromDate}&ToDate=${data.ToDate}`)
        }
        else{
            response = await HTTP.get(`${REMAINING_AMOUNT_DETAILS}?CompanyId=${userData.companyId}&AcYear=${userData.acYear}`)
        }
        dispatch(getRemainingAmount(response?.data?.Data))
    }
}
const getRemainingAmount = (data) => {
    return {
        type : REMAINING_AMOUNT,
        data : data
    }
}