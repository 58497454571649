import axios from "axios";
import { headers } from "../../HTTP/HTTP";
import { getAuthLoginUser } from "../../Services/AuthService/AuthService";
import { ApiHttp, BILL_PRINT_PDF } from "../../Shared/Constants/ApiConstant";

export const printBillPDF = async (billNo) => {
  let loginUser = getAuthLoginUser();
  try {
    const payload = {
      BillNo: billNo,
      CompanyId: loginUser.companyId,
      AcYear: loginUser.acYear
    }

    const axiosConfig = {
      url: `${ApiHttp}${BILL_PRINT_PDF}`,
      method: 'POST',
      headers: {
        'Authorization': headers.headers.Authorization,
      },
      data: payload,
      // responseType: 'blob', 
    };
    await axios(axiosConfig).then(res => {
      // const blob = new Blob([res.data], { type: 'application/pdf' });
      // const url = window.URL.createObjectURL(blob);
      // const iframe = document.createElement('iframe');
      // iframe.src = url;
      // iframe.style.display = 'none';
      // document.body.appendChild(iframe);
      // iframe.contentWindow.focus();
      // iframe.contentWindow.print();

      // Create an iframe element
      var iframe = document.createElement("iframe");
      iframe.id = "printIframe";
      iframe.style.display = 'none'
      document.body.appendChild(iframe);
      var iframeDoc = iframe.contentDocument;
      iframeDoc.write(res.data.Data);
      var footerElement = iframeDoc.getElementById("footer");
      if (footerElement) {
        footerElement.parentNode.removeChild(footerElement);
      }
      iframeDoc.close();
      iframe.onload = function() {
        iframe.contentWindow.print();
      }
    });

    

  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};
