import { combineReducers } from "redux";
import { itemMasterReducer } from "./ItemMasterReducer";
import { companyMasterReducer } from "./CompanyMasterReducer";
import { billMasterReducer } from "./billMasterReducer";
import { userMasterReducer } from "./UserMasterReducer";
import { orderDetailsByUserReducer } from "./OrderDetailsByUserReducer";
import { confirmOrdersReducer } from "./ConfirmOrderReducer";
import { dailyOrderSummaryReducer } from "./dailyOrderSummaryReducer";
import { getTotalOrderReducer } from "./GetTotalOrderCount";
import { getTopUserReducer } from "./GetTopUserReducer";
import { getTopItemReducer } from "./GetTopItemReducer";
import { paymentDetailreducer } from "./PaymentReducer";
import { AgrMasterReducer } from "./AGRmasterReducer";
import { allPartyReducer } from "./AllPartyReducer";
import { partyMasterReducer } from "./PartyMasterReducer";
import { getPartyCode } from "./PartyCodeReducer";
import { remainingAmountReducer } from "./RemainingAmountReducer";
import { getAllCompaniesReducer } from "./GetAllCompanyReducer";
import { customerInfoAboutServiceReducer } from "./CustomerServiceInfoReducer";


export const rootReducer = combineReducers({
    items : itemMasterReducer,
    company : companyMasterReducer,
    orders : billMasterReducer,
    user : userMasterReducer,
    orderDetails : orderDetailsByUserReducer,
    confirmOrders : confirmOrdersReducer,
    dailyOrder : dailyOrderSummaryReducer,
    orderCount : getTotalOrderReducer,
    topUser : getTopUserReducer,
    topItem : getTopItemReducer,
    paymentDetail : paymentDetailreducer,
    agrDetail:AgrMasterReducer,
    allParty : allPartyReducer,
    partyDetail:partyMasterReducer,
    partyCode:getPartyCode,
    remainingAmount:remainingAmountReducer,
    allCompany : getAllCompaniesReducer,
    customerServiceInfo:customerInfoAboutServiceReducer


})