import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { addNewItems, deleteItems, getAllItems, updateItems } from '../../Redux/Actions/ItemMasterAction';
import { confirmationDeletePopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import {AiOutlineCloudDownload} from 'react-icons/ai'
// import { ImagePath, toBase64 } from '../../Shared/Constants/Constant';
// import OpenImage, { imagePopupService } from '../../Components/OpenImage/OpenImage';
import { roundValue } from '../../Shared/Constants/Constant';
import { RiDeleteBin2Line } from 'react-icons/ri'
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import { MdDone } from 'react-icons/md'
import { GoDash } from 'react-icons/go'

/*eslint eqeqeq: "off"*/
function ItemMaster() {
    const [show, setshow] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset , setValue} = useForm();
    const AllItems = useSelector(state => state.items.items);
    const dispatch = useDispatch();
    const [isAdd, setisAdd] = useState(true);

    // let imageRef = useRef();

    const onSubmit = (data) => {
        if(isAdd){
            dispatch(addNewItems(data))
        }else{
            dispatch(updateItems(data))
        }
        reset()
        handleClose()
        // imageRef.current.style.display = 'none';
    };

    useEffect(() => {
     dispatch(getAllItems())
    }, [])
    
    const handleShow = () => {
        setshow(true)
        setValue('IsActive' , 'true')
    }
    const handleClose = () => {
        setshow(false)
        setisAdd(true)
        reset()
    }

    const deleteItem = (itemId, itemImage) => {
        confirmationDeletePopup().then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteItems(itemId , itemImage))
              return true
            }
          })
    }

    const editItem = async (itemId) => {
        setisAdd(false)
        handleShow();
        const editData = AllItems.find(x => x.ItemId === itemId)
        for(let key in editData){
            setValue(key , editData[key]);
        }
        // setTimeout(() => {
        //     if(editData.ItemImage){
        //         imageRef.current.style.display = 'block';
        //         imageRef.current.src = `${ImagePath}/${editData.ItemImage}`;
        //     }
        // }, 100);
    }

    // const getImage = async (e) => {
    //     if(e.target.files[0]){
    //         imageRef.current.src = await toBase64(e.target.files[0]) ;
    //         imageRef.current.style.display = 'block';
    //     }
    // }

    // const resetImage = () => {
    //     imageRef.current.style.display = 'none';
    //     imageRef.current.src = '' ;
    // }

    const columns = [
        {
            name: 'Item Id',
            selector: row => row.ItemId,
            sortable : true,
            width:'110px',
        },
        // {
        //     name: 'Image',
        //     selector: (row,index) =>{
        //                     return <img
        //                         src={`${ImagePath}/${row.ItemImage}`}
        //                         onClick={() => imagePopupService(row.ItemImage ? `${ImagePath}/${row.ItemImage}` : undefined)}
        //                         width={50}
        //                         height={50}
        //                         key={index}
        //                         className='m-1 cursor_pointer'
        //                         alt=""
        //                     />
        //     },
        //     minWidth: '100px',
        //     maxWidth: '110px',
        // },
        {
            name: 'Name',
            selector: row => row.ItemName,
            sortable : true,
            minWidth: '150px',
            maxWidth: '300px',
        },
        {
            name: 'Rate',
            selector: row => roundValue(row.ItemRate),
            right: true,
            sortable : true,
            minWidth: '150px',
            maxWidth: '200px',
        },
        {
            name: 'Code',
            selector: row => row.ItemCode,
            sortable : true,
            minWidth: '200px',
        },
        {
            name: 'IsActive',
            selector: row => row.IsActive ? 'Active' : 'Inactive',
            sortable : true,
            width: '120px',
        },
        {
            name: 'Labour',
            selector: row => row.Labour ? <MdDone /> : <GoDash />,
            sortable : true,
            width: '110px',
            center : true
        },
        {
            name: 'Description',
            selector: row => row.ItemDescription,
            sortable : true,
            wrap: true,
            minWidth:'250px',
            maxWidth:'350px',
        },
        {
            name: 'Actions',
            selector: (row) => <div className='white_space_nowrap'>
                <button className='btn btn-warning py-1 fs-14px'  onClick={() => editItem(row.ItemId)}>EDIT</button>
                <button className='btn btn-danger py-1 ms-2 fs-14px' onClick={() => deleteItem(row.ItemId, row.ItemImage)}>DELETE</button>
            </div>,
            width : '200px'
         }
        // {
        //     name: '',
        //     selector: (row) => <div className='white_space_nowrap'style={{ width: '450px' }}>
        //     </div>,
        //     sortable : true
        // },
    ];

    const MobileColumns = [
        {
            name: 'Name',
            selector: row => row.ItemName,
            sortable : true,
            minWidth: '150px',
            maxWidth: '300px',
        },
        {
            name: 'Rate',
            selector: row => roundValue(row.ItemRate),
            right: true,
            sortable : true,
            minWidth: '150px',
            maxWidth: '200px',
        },
    ];
    const filterField = [
        {
            displayText : 'Name',
            fieldName : 'ItemName',
            type : 'text'
        },
        {
            displayText : 'Rate',
            fieldName : 'ItemRate',
            type : 'number'
        },
        {
            displayText : 'Code',
            fieldName : 'ItemCode',
            type : 'text'
        },
    ]
    const ExpandedComponent = () => ({ data }) => (
        <div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Name :</div>
                <div className='ms-2'>{data.ItemName}</div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Item Rate : </div>
                <div className='ms-2'> {roundValue(data.ItemRate)}</div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Item Code :</div>
                <div className='ms-2'> {data.ItemCode} </div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Is Active : </div>
                <div className='ms-2'> {data.IsActive ? 'Active' : 'Inactive'} </div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Labour : </div>
                <div className='ms-2'> {data.Labour ? <MdDone /> : <GoDash />} </div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Description : </div>
                <div className='ms-2'> {data.ItemDescription} </div>
            </div>
            <div className='d-flex align-items-center p-2 ps-5'>
                <div className='fw-bold'>Actions : </div>
                <div className='ms-2'>
                    <button className='btn btn-warning p-1' onClick={() => editItem(data.ItemId)}><HiOutlinePencilSquare size={20} /></button>
                    <button className='btn btn-danger ms-2 p-1' onClick={() => deleteItem(data.ItemId, data.ItemImage)}><RiDeleteBin2Line size={20} /></button>
                </div>
            </div>
        </div>
    );
  return (
    <>
        <div className='dashboard_bg'>
            <Row className='m-0 w-100 mb-2'>
                <Col xs={6} className='d-flex align-items-center justify-content-start p-0'>
                    <h2 className='page_heading'>Items</h2> 
                </Col>
                <Col xs={6} className='d-flex align-items-center justify-content-end p-0'>
                    <button className='button_dark py-2 px-4' onClick={handleShow}>Add +</button>
                </Col>
            </Row>
            <Modal size='lg' show={show} fullscreen='md-down' onHide={handleClose} backdrop="static" >
                <Modal.Header closeVariant='white' closeButton className='border-0 black_Background text-white'>
                    <Modal.Title>{isAdd ? 'ADD NEW ITEM' : 'EDIT'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body' style={{height: '70vh', overflowY: 'scroll'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6}>
                                <label className='form_label'>Item Name:</label>
                                <input type="text" className='form_input' {...register("ItemName", { required: true })} />
                                {errors.ItemName && <span className='error_message'>Item Name is required</span>}
                            </Col>
                            <Col lg={6}>
                                <label className='form_label'>Item Rate:</label>
                                <input type="number" min={0} className='form_input' autoComplete="on" {...register("ItemRate", { required: {value : true, message : 'Item Rate is required'} , min:{value :0, message: "You cannot put negative value"} })} />
                                {errors.ItemRate && <span className='error_message'>{errors.ItemRate.message}</span>}
                            </Col>
                            <Col lg={6}>
                                <label className='form_label'>Item Code:</label>
                                <input type="text" className='form_input' autoComplete="on" {...register("ItemCode", { required: true })} />
                                {errors.ItemCode && <span className='error_message'>Item Code is required</span>}
                            </Col>
                            <Col lg={6}>
                                <label className='form_label'>IsActive:</label>
                                <input type="checkbox" style={{width:'25px', height : '25px'}} className='mt-2' autoComplete="on" {...register("IsActive")} />
                            </Col>
                            <Col lg={6}>
                                <label className='form_label'>Item Description:</label>
                                <textarea name="itemDescription" className='resize_none w-100 border_light_gray focus_none' style={{ height:'70px'}} {...register("ItemDescription", { required: true })}></textarea>
                                {errors.ItemDescription && <span className='error_message'>Item Description is required</span>}
                            </Col>
                            <Col lg={6}>
                                <label className='form_label'>Labour:</label>
                                <input type="checkbox" style={{width:'25px', height : '25px'}} className='mt-2' autoComplete="on" {...register("Labour")} />
                            </Col>
                            {/* <Col lg={12}>
                                <label className='form_label' >Item Image:</label>
                                <label htmlFor='file' className='selectItemImage' onClick={resetImage}><AiOutlineCloudDownload size={25} className='me-2'/>Select Image</label>
                                <input type="file" className='mt-2 d-none' id='file' autoComplete="on" {...register("file")} onChangeCapture={getImage} />
                                {errors.file && <span className='error_message'>Item Image is required</span>}
                            </Col>
                            <Col lg={12}>
                                <img src='' style={{display:'none'}} className='my-4' ref={imageRef} alt="" width='100%' height='100%'   />
                            </Col> */}
                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <button className='button_dark px-5 py-2' onClick={handleSubmit(onSubmit)}>Save</button>
                </Modal.Footer>
            </Modal>

            <CustomDataTable columns={columns} MobileColumns={MobileColumns} data={AllItems} filterField={filterField} ExpandedComponent={ExpandedComponent} />

        </div>
    </>
  )
}

export default ItemMaster