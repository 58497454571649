import React, { useEffect, useState } from 'react'
import PaymentPopup from './PaymentPopup'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import moment from 'moment';
import { calculateSum, conditionalRowStyles, customSort, isMobileScreen, roundValue, tableCustomStyles } from '../../Shared/Constants/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { getBillByTrno, getOrder } from '../../Redux/Actions/billMasterAction';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import { getAuthLoginUser } from '../../Services/AuthService/AuthService';
import { ConfirmOrder, getBillMasterDetails } from '../../Redux/Actions/BillMasterDetailsAction';
import { deleteBillPayment, getPaymentDetail, updatePayment } from '../../Redux/Actions/PaymentAction';
import axios from 'axios';
import { ApiHttp, BILL_PRINT_PDF, UPDATE_ORDERS_BY_ADMIN } from '../../Shared/Constants/ApiConstant';
import { headers } from '../../HTTP/HTTP';
import Select from "react-select";
import { printBillPDF } from '../../Redux/Actions/BillPrint';
import { useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { getAllParty } from '../../Redux/Actions/AllPartyAction';

import DataTable from 'react-data-table-component';
import { loaderService } from '../../Components/Loader/Loader';
import { errorPopup, successPopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import { downloadBillPDF } from '../../Redux/Actions/DownloadBill';
import { NavLink, useNavigate } from 'react-router-dom';

function PaymentGrid() {
  const dispatch = useDispatch();
  const alldata = useSelector(state => state.confirmOrders.confirmOrders)
  let [showData, setShowData] = useState(alldata)
  const [showPaymentDetail, setshowPaymentDetail] = useState(false)
  const paymentDetail = useSelector(state => state.paymentDetail.paymentDetail)
  const BillDetail = useSelector(state => state.orders.orders)
  const AllItems = useSelector(state => state.items.items);
  const [show, setshow] = useState(false)
  const navigate = useNavigate();
  const [billDetailshow, setbillDetailshow] = useState(false)
  const [BillNo, setBillNo] = useState()
  const loginUser = getAuthLoginUser()
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  let [selectedOptions, setSelectedOptions] = useState();
  let [optionList, setoptionList] = useState([])
  const allParty = useSelector(state => state.allParty.allParty)
  let [TotalAmount, setTotalAmount] = useState(0)
  let [TotalPayment, setTotalPayment] = useState(0)
  let [TotalAdjust, setTotalAdjust] = useState(0)
  let [TotalRemaining, setTotalRemaining] = useState(0)
  const [isExpand, setisExpand] = useState(isMobileScreen())
  let [editPaymentObj, seteditPaymentObj] = useState({})
  const checkOrder = (id) => {
    dispatch(getBillByTrno(id))
    setbillDetailshow(true)
  }
  useEffect(() => {
    // setoptions([...allParty.map(x => {
    //     return { value: x.PartyCode, label: x.PartyName }
    // })])
    setoptionList([...allParty.map(x => {
      return { value: x.PartyCode, label: x.PartyName }
    })])
  }, [allParty])

  useEffect(() => {
    // dispatch(getBillMasterDetails())
    dispatch(ConfirmOrder([]))
    dispatch(getAllParty())

  }, [])

  const [filterText, setfilterText] = useState()
  let [filteredItems, setfilteredItems] = useState([])
  const [obj, setobj] = useState({})
  useEffect(() => {
    if (alldata) {
      setfilteredItems([...alldata])
      loaderService(false)
    }
    showData = alldata;
    setShowData(showData);

    TotalAmount = calculateSum(alldata, 'PayableAmount');
    setTotalAmount(TotalAmount)
    TotalPayment = calculateSum(alldata, 'PaymentAmount');
    setTotalPayment(TotalPayment)
    TotalAdjust = calculateSum(alldata, 'BillAdjustAmount');
    setTotalAdjust(TotalAdjust)
    TotalRemaining = calculateSum(alldata, 'BillRemainingAmount');
    setTotalRemaining(TotalRemaining)
  }, [alldata])

  const getSubHeaderComponent = () => {
    const getValue = (e) => {
      obj[e.target.name] = `String(item.${e.target.name}).toUpperCase()?.includes('${e.target.value.toUpperCase()}')`;
      setobj({ ...obj })

      let str = [];
      for (let key in obj) {
        str.push(obj[key])
      }
      filteredItems = alldata.filter((item) => eval(str?.join(' && ')));
      setfilterText(e.target.value)
      setfilteredItems([...filteredItems])
    }

    return (
      <div className='w-100'>
        <Row>
          {/* {filterField.map((x, i) => {
            return <Col md={3} key={i}>
              <label className='form_label'>{x.displayText}</label>
              <input type={x.type} name={x.fieldName} className={`form_input ${(x.type == 'number') ? 'text-end' : ''}`} onChange={getValue} />
            </Col>
          })
          } */}
        </Row>
      </div>
    );
  };
  
  const checkPayment = (BillNo) => {
    let order = alldata?.find(x => x.BillNo == BillNo)
    setBillNo(order.BillNo)
    setshow(true)
  }
  const checkPaymentDetail = (BillNo) => {
    let order = alldata?.find(x => x.BillNo == BillNo)
    dispatch(getPaymentDetail(order))
    setshowPaymentDetail(true)
  }
  const paymentDetailClose = () => {
    setshowPaymentDetail(false)
    editPaymentObj = {}
    seteditPaymentObj(editPaymentObj)
  }

  const printBill = async (billNo) => {
    printBillPDF(billNo);
  };
  
  const downloadBill = async (billNo) => {
    let isExistData = alldata.find(element => element.BillNo == billNo)
    downloadBillPDF(billNo, isExistData)
  }


  const gotToBill = (data) => {
    if(data.BillNo){
      navigate('/bills', { state: { BillNo : data.BillNo,PartyCode:data.PartyCode} });
    }
  }
  let columns = [
    {
      name: 'Edit',
      selector: (row, i) => (
        <div className='white_space_nowrap'>
            <Button className='btn btn-warning fs-12px' onClick={()=>gotToBill(row)}>Edit</Button>
        </div>
      ),
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: 'Party Name',
      selector: row => row?.PartyName,
      sortable: true,
      wrap: true,
      maxWidth: '250px',
      minWidth: '270px',
    },
    {
      name: 'Bill Date',
      selector: row => moment(row?.BillDate).format('DD-MM-YYYY'),
      sortable: true,
      maxWidth: '120px',
      minWidth: '120px',
    },
    {
      name: 'MakePayment',
      selector: (row, i) => <div className='white_space_nowrap'>
        {row.BillRemainingAmount == 0 ?
          <Button variant="outline-success px-3" className='fs-14px' >Done</Button>
          :
          <Button className='btn btn-info fs-12px' onClick={() => checkPayment(row?.BillNo)} >Payment</Button>
        }
      </div>,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: 'BillNo.',
      selector: (row, i) => <div className='white_space_nowrap text-end'>
        <span className='btn ms-2 border shadow' onClick={() => checkOrder(row?.BillNo)} >{row?.BillNo}</span>
      </div>,
      maxWidth: '120px',
      minWidth: '120px',
    },
    {
      name: 'Mobile',
      selector: row => row?.Mobile,
      sortable: true,
      right: true,
      maxWidth: '140px',
      minWidth: '130px',
    },
    {
      name: 'VehicleNo.',
      selector: row => row?.VehicleNo,
      right: true,
      maxWidth: '130px',
      minWidth: '130px',
    },
    {
      name: 'Model',
      selector: row => row?.CarCompanyModelName,
      sortable: true,
      //right: true,
      maxWidth: '130px',
      minWidth: '130px',
    },
    {
      name: 'Rate',
      selector: row => roundValue(row?.Rate),
      sortable: true,
      right: true,
      maxWidth: '130px',
      minWidth: '130px',
    },
    {
      name: 'Amount',
      selector: row => roundValue(row?.PayableAmount).toLocaleString('en-IN'),
      sortable: true,
      right: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: 'Payment',
      selector: (row, i) => <div className='white_space_nowrap text-end'>
        <span className='btn ms-2 border shadow' onClick={() => checkPaymentDetail(row?.BillNo)} > {roundValue(row?.PaymentAmount)}</span>
      </div>,
      right: true,
      sortable: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: 'Adjust',
      selector: row => roundValue(row?.BillAdjustAmount),
      sortable: true,
      right: true,
      maxWidth: '120px',
      minWidth: '120px',
    },
    {
      name: 'Remaining',
      selector: row => roundValue(row?.BillRemainingAmount),
      sortable: true,
      right: true,
      maxWidth: '150px',
      minWidth: '150px',
    },

    {
      name: 'Bill',
      selector: (row, i) => <div className='white_space_nowrap'>
        <Button className='btn btn-warning fs-12px' onClick={() => printBill(row?.BillNo)} >Print Bill</Button>
        <Button className='btn btn-primary fs-12px ms-2' onClick={() => downloadBill(row?.BillNo)} >Download Bill</Button>
      </div>,
      maxWidth: '200px',
      minWidth: '210px',
    }
  ];
  let MobileColumns = [
    {
      name: 'Party Name',
      selector: row => row?.PartyName,
      sortable: true,
      wrap: true,
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: 'BillNo.',
      selector: (row, i) => row?.BillNo,
      maxWidth: '120px',
      minWidth: '120px',
      right: true
    }
  ];
  const editPayment = (editRow) =>{
    editPaymentObj = {...editRow}
    seteditPaymentObj(editPaymentObj)
  }
  const deletePayment = (data) =>{
    let payload = {
      CompanyId:data.CompanyId,
      AcYear:data.AcYear,
      BillNo:data.BillNo,
      SeqNo:data.SeqNo
    }
    dispatch(deleteBillPayment(payload))
  }

  const getPaymentValue = (e) => {
    let decimalValue = e.target.value.split('.');
    if(decimalValue.length < 2 || decimalValue[1].length < 3){
      editPaymentObj[e.target.name] = Number(e.target.value);
      seteditPaymentObj({...editPaymentObj})
    }
  }
  const savePayment = () =>{
    let order = alldata?.find(x => x.BillNo == editPaymentObj.BillNo)
    console.log(order);
    if(order?.BillRemainingAmount < (Number(editPaymentObj?.PaymentAmount) + Number(editPaymentObj?.AdjustAmount))){
      errorPopup('Amount of Bill not more than remaining amount!')
      editPaymentObj = {}
      seteditPaymentObj(editPaymentObj)
    }
    else{
      dispatch(updatePayment(editPaymentObj))
      successPopup('Bill payment amount edited successfully!')
      editPaymentObj = {}
      seteditPaymentObj(editPaymentObj)
    }
   
  }  
  let paymentDetailColumn = [
    {
      name: 'Seq No',
      selector: row => row.SeqNo,
      width: '90px',
    },
    {
      name: 'AcYear',
      selector: row => row.AcYear,
      minWidth: '90px',
    },
    {
      name: 'PaymentDate',
      selector: row => moment(row.PaymentDate).format("DD-MM-YYYY"),
      minWidth: '130px',
    },
    {
      name: 'Payment',
      right: true,
      selector: row => <>
         {editPaymentObj.SeqNo == row.SeqNo ? 
          <input type='number' className='text-end form_input' name='PaymentAmount' value={editPaymentObj?.PaymentAmount} style={{width:'150px'}} onChange={getPaymentValue} /> 
         : roundValue(row?.PaymentAmount)}
      </> ,
      width: '200px'
    },
    {
      name: 'Adjust',
      right: true,
      selector: row => <>
      {editPaymentObj.SeqNo == row.SeqNo ? 
       <input type='number' className='text-end form_input' name='AdjustAmount' value={editPaymentObj?.AdjustAmount} style={{width:'150px'}} onChange={getPaymentValue} /> 
      : roundValue(row?.AdjustAmount)}
   </> ,
      width: '200px'
    },
    {
      name: 'Remark',
      selector: row => row.Remark,
      minWidth: '150px',
    },
    {
      name: 'Actions',
      selector: (row) => <div className='white_space_nowrap'>
          {editPaymentObj.SeqNo == row.SeqNo ?
            <button className='btn btn-success py-1 fs-14px'  onClick={() => savePayment()}>SAVE</button>
          :
            <button className='btn btn-warning py-1 fs-14px'  onClick={() => editPayment(row)}>EDIT</button>
          }
          <button className='btn btn-danger py-1 ms-2 fs-14px' onClick={() => deletePayment(row)}>DELETE</button>
      </div>,
      width : '200px'
    }
    
  ]
  let paymentDetailColumnMobile = [
    {
      name: 'PaymentDate',
      selector: row => moment(row.PaymentDate).format("DD-MM-YYYY"),
      minWidth: '130px',
    },
    {
      name: 'Payment',
      right: true,
      selector: row => roundValue(row?.PaymentAmount),
      minWidth: '100px',
    }
  ]
  const checkOrderColumn = [
    {
      name: 'Seq No',
      selector: row => row.SeqNo,
      minWidth: '100px',
    },
    {
      name: 'Item',
      selector: row => AllItems.find(x => x.ItemId == row.ItemId)?.ItemName,
      minWidth: '250px',
    },
    {
      name: 'pieces',
      right: true,
      selector: row => row.Pcs >= 1 ? row.Pcs :'',
      minWidth: '125px',
    },
    {
      name: 'Rate',
      right: true,
      selector: row => row?.Rate >= 1 ? roundValue(row?.Rate) : '',
      minWidth: '125px',
    },
    {
      name: 'Amount',
      right: true,
      selector: row => roundValue(row?.Amount),
      minWidth: '125px',
    }
  ]
  const checkOrderColumnMobile = [
    {
      name: 'Item',
      selector: row => AllItems.find(x => x.ItemId == row.ItemId)?.ItemName,
      width: '220px',
    },
    {
      name: 'Amount',
      right: true,
      selector: row => roundValue(row?.Amount),
    }
  ]
  const handleClose = () => {
    setbillDetailshow(false)
  }
  // const filterField = [
  //   {
  //     displayText: 'Bill Date',
  //     fieldName: 'BillDate',
  //     type: 'date'
  //   },
  //   {
  //     displayText: 'Party Name',
  //     fieldName: 'PartyName',
  //     type: 'text'
  //   },
  //   {
  //     displayText: 'Mobile',
  //     fieldName: 'Mobile',
  //     type: 'text'
  //   },
  //   {
  //     displayText: 'Vehicle No',
  //     fieldName: 'VehicleNo',
  //     type: 'text'
  //   }
  // ]
  
  function handleSelect(data) {
    selectedOptions = data;
    setSelectedOptions(selectedOptions);
  }
  
  const clearFilter =() => {
    reset((formValues) => ({
      ...formValues,
      FromDate: "",
      ToDate: "",
      MobileNo: "",
      VehicleNo: "",
    }))
    
    selectedOptions = null;
    setSelectedOptions(selectedOptions)
    // dispatch(getBillMasterDetails({
    //   "FromDate": "",
    //   "ToDate": "",
    //   "MobileNo": "",
    //   "VehicleNo": "",
    //   "PartyCode": ""
    // }))
    showData = []
    setShowData(showData)
    TotalAmount = 0;
    TotalPayment = 0;
    TotalAdjust = 0;
    TotalRemaining = 0;
    setTotalAmount(TotalAmount)
    setTotalPayment(TotalPayment)
    setTotalAdjust(TotalAdjust)
    setTotalRemaining(TotalRemaining)

    // dispatch(ConfirmOrder([]))
  }

  const onSubmit = (data) => {
    data.PartyCode = selectedOptions?.value ?? ''
    dispatch(getBillMasterDetails(data))
  }
  const ExpandedCheckOrderComponent = () => ({ data }) => (
    <div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Sequence No. :</div>
        <div className='ms-2'>{data.SeqNo}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Item :</div>
        <div className='ms-2'>{AllItems.find(x => x.ItemId == data.ItemId)?.ItemName}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Pieces :</div>
        <div className='ms-2'>{data.Pcs}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Rate :</div>
        <div className='ms-2'>{data.Rate}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Amount :</div>
        <div className='ms-2'>{data.Amount}</div>
      </div>
    </div>
  );
  const ExpandedPaymentDetailComponent = () => ({ data }) => (
    <div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Seq No. :</div>
        <div className='ms-2'>{data.SeqNo}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Ac Year :</div>
        <div className='ms-2'>{data.AcYear}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Payment Date :</div>
        <div className='ms-2'>{moment(data.PaymentDate).format("DD-MM-YYYY")}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Payment :</div>
        <div className='ms-2'>{roundValue(data?.PaymentAmount)}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Adjust Amount :</div>
        <div className='ms-2'>{roundValue(data?.AdjustAmount)}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Remark :</div>
        <div className='ms-2'>{data.Remark? data.Remark : "No remarks"}</div>
      </div>
    </div>
  );
  const ExpandedComponent = () => ({ data }) => (
    <div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Party Name :</div>
        <div className='ms-2'>{data.PartyName}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Bill Date :</div>
        <div className='ms-2'>{data.BillDate}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Make Payment :</div>
        <div className='white_space_nowrap'>
          {data.BillRemainingAmount == 0 ?
            <Button variant="outline-success py-0 ms-2" className='fs-12px' >Done</Button>
            :
            <Button className='btn btn-info fs-12px py-0 ms-2' onClick={() => checkPayment(data?.BillNo)} >Payment</Button>
          }
        </div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Bill No. :</div>
        <div className='white_space_nowrap text-end'>
          <span className='btn ms-2 border shadow fs-12px py-0 ms-2' onClick={() => checkOrder(data?.BillNo)} >{data?.BillNo}</span>
        </div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Mobile No. :</div>
        <div className='ms-2'>{data.Mobile}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Vehicle No. :</div>
        <div className='ms-2'>{data.VehicleNo}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Car Company Model Name :</div>
        <div className='ms-2'>{data.CarCompanyModelName}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Rate :</div>
        <div className='ms-2'>{data.Rate}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Payble Amount :</div>
        <div className='ms-2'>{data.PayableAmount}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Payment :</div>
        <div className='white_space_nowrap text-end'>
          <span className='btn ms-2 border shadow fs-12px py-0 ms-2' onClick={() => checkPaymentDetail(data?.BillNo)} > {roundValue(data?.PaymentAmount)}</span>
        </div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Adjust Amount :</div>
        <div className='ms-2'>{roundValue(data?.BillAdjustAmount)}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Remaining Amount :</div>
        <div className='ms-2'>{roundValue(data?.BillRemainingAmount)}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Print Bill :</div>
        <div className='white_space_nowrap'>
          <Button className='btn btn-warning fs-12px py-0 ms-2' onClick={() => printBill(data?.BillNo)} >Print</Button>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className='dashboard_bg'>
        <Row className='m-0 w-100 mb-2'>
          <Col xs={6} className='p-0'>
            <h2>Payment</h2>
          </Col>

        </Row>
        <Row className='table_section py-4'>
          <Col xxl={3} xl={4} lg={4} md={6} >
            <label className='form_label'>From Date:</label>
            <input type="date" className='form_input' {...register("FromDate")} />
          </Col>
          <Col xxl={3} xl={4} lg={4} md={6}>
            <label className='form_label'>To Date:</label>
            <input type="date" className='form_input' {...register("ToDate")} />
          </Col>
          <Col xxl={3} xl={4} lg={4} md={6}>
            <label className='form_label'>Customer Name:</label>
            <Select
              options={optionList}
              placeholder="Select Party"
              value={selectedOptions}
              onChange={handleSelect}
              isSearchable={true}
              name='PartyCode'
              // {...register("PartyCode")}
            />
            
          </Col>
          <Col xxl={3} xl={4} lg={4} md={6} >
            <label className='form_label'>Mobile No:</label>
            <input type="number" className='form_input' {...register("MobileNo")} />
          </Col>
          <Col xxl={3} xl={4} lg={4} md={6}>
            <label className='form_label'>Vehicle No:</label>
            <input type="text" className='form_input' {...register("VehicleNo")} />
          </Col>

          <Col xxl={3} xl={3} lg={4} md={6}>
            <label className='form_label remove-space w-auto invisible'>'</label>
            <div className='d-flex justify-content-start '>
              <button className='form_input btn btn-success w-auto px-4' onClick={handleSubmit(onSubmit)}>
                Search
              </button>
              <button className='form_input btn btn-danger w-auto px-4 ms-2' onClick={clearFilter}>
                Reset
              </button>
            </div>
          </Col>
        </Row>
        {/* <CustomDataTable columns={columns} data={alldata} /> */}
        {
          (showData.length > 0 && isMobileScreen()) && 
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label=" Expandable"
              checked={isExpand}
              className='d-flex justify-content-end mt-3'
              onChange={(e) => setisExpand(e.target.checked)}
            />
          </Form>
        }
        <div className='table_section bg-transparent my-3'>
          {showData.length > 0 && 
            <DataTable
              columns={isMobileScreen() && isExpand ? MobileColumns : columns}
              data={showData}
              pagination
              paginationPerPage={15}
              sortFunction={customSort}
              fixedHeader
              fixedHeaderScrollHeight="75vh"
              conditionalRowStyles={conditionalRowStyles}
              customStyles={tableCustomStyles}
              expandableRows={isExpand}
              expandableRowsComponent={ExpandedComponent()}
              // subHeader={filterField ? getSubHeaderComponent() : false}
              // subHeaderComponent={filterField ? getSubHeaderComponent() : false}
            />}
          <div className='custom-table-footer p-1 px-2 d-flex justify-content-between'
            style={{ fontWeight: '',color: 'white', background: 'radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)', borderRadius: '0 0 10px 10px' }}
          >
            <div md={12} lg={3} className='text-center'> <span className='fw-bold'> Total count: </span> {showData.length}</div>
              <div md={12} lg={3} className='text-center'> <span className='fw-bold'> Total Amount: </span> {roundValue(TotalAmount)}</div>
              <div md={12} lg={3} className='text-center'> <span className='fw-bold'> Total Adjust: </span>{roundValue(TotalAdjust)} </div>
              <div md={12} lg={3} className='text-center'> <span className='fw-bold'> Total Payment: </span>{roundValue(TotalPayment)} </div>
              <div md={12} lg={3} className='text-center'><span className='fw-bold'> Total Remaining: </span>{roundValue(TotalRemaining)}</div>
          </div>
        </div>
      </div>

      <Modal size='xl' className='modal' show={showPaymentDetail} fullscreen='md-down' onHide={paymentDetailClose} backdrop="static" >
        <Modal.Header className='border-0 p-0'>
        </Modal.Header>
        <Modal.Body className='p-0 mb-0'>
          <CustomDataTable columns={paymentDetailColumn} MobileColumns={paymentDetailColumnMobile} data={paymentDetail} ExpandedComponent={ExpandedPaymentDetailComponent} />
        </Modal.Body>
        <Modal.Footer className='py-1'>
          <Button variant="outline-danger" className="fs-14px" onClick={paymentDetailClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal size='xl' show={billDetailshow} fullscreen='md-down' onHide={handleClose} backdrop="static" >
        <Modal.Header className='border-0 p-0'>
        </Modal.Header>
        <Modal.Body className='py-0 p-0'>
          <CustomDataTable columns={checkOrderColumn} MobileColumns={checkOrderColumnMobile} data={BillDetail} ExpandedComponent={ExpandedCheckOrderComponent} />
        </Modal.Body>
        <Modal.Footer className='py-1'>
          <Button variant="outline-danger" className="fs-14px" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <PaymentPopup state={{ show, setshow, BillNo, setBillNo }} />
    </>
  )
}

export default PaymentGrid