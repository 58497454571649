import { HTTP } from "../../HTTP/HTTP"
import { getAuthLoginUser } from "../../Services/AuthService/AuthService"
import { ADD_COMPANIES, DELETE_COMPANY, EDIT_COMPANIES, GET_COMPANIES } from "../../Shared/Constants/ApiConstant"
import { errorPopup, successPopup } from "../../Shared/Constants/PopupConstant/PopupContant"
import { GET_COMPANY } from "../Types/Type"

export const getAllCompanies = () => {
    let userData = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.get(`${GET_COMPANIES}?CompanyId=${userData.companyId}`);
        dispatch(getCompany(response?.data?.Data))
    }
}

export const addNewCompanies = (value) => {
    let userData = getAuthLoginUser();
    value.UserId = userData.userId;
    return async (dispatch) => {
        await HTTP.post(ADD_COMPANIES , value)
        dispatch(getAllCompanies())
    }
}

export const updateCompany = (value) => {
    let userData = getAuthLoginUser();
    const formData = new FormData();
    formData.append('Name', value.Name);
    formData.append('Add1', value.Add1);
    formData.append('Add2', value.Add2);
    formData.append('Add3', value.Add3);
    formData.append('Gstno',value.Gstno);
    formData.append('CGST',value.CGST);
    formData.append('SGST',value.SGST);
    formData.append('EmailId', value.EmailId);
    formData.append('MobileNo', value.MobileNo);
    formData.append('file', value.file ??'');
    formData.append('CompanyId',  userData.companyId);
    formData.append('UserId',  userData.userId);

    return async (dispatch) => {
        let response = await HTTP.put(EDIT_COMPANIES , formData)
        dispatch(getAllCompanies())
        if(response.data.StatusCode == 200){
            successPopup('Company details successfully updated')
        }
        else{
            errorPopup(response.data.Message)
        }
    }
}

export const deleteCompanies = (CompanyId) => {
    return async (dispatch) => {
        await HTTP.delete(`${DELETE_COMPANY}?CompanyId=${CompanyId}`)
        dispatch(getAllCompanies())
    }
}

const getCompany = (data) => {
    return {
        type : GET_COMPANY,
        data : data
    }
}