import { REMAINING_AMOUNT } from "../Types/Type";

let initialState = {
    remainingAmount : []
}

export const remainingAmountReducer = (state = initialState , action) =>{
    switch(action.type){
        case  REMAINING_AMOUNT: 
            return {
                remainingAmount : action.data
            };
        default : 
            return state;
    }
}