import { Button, Col, Collapse, Fade, Row, Tab, Tabs } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { getOrderCount } from '../../Redux/Actions/GetTotalOrderCountAction';
import { useDispatch, useSelector } from 'react-redux';
import { getTopUserDateWise } from '../../Redux/Actions/GetTopUserAction';
import { getTopItemDateWise } from '../../Redux/Actions/GetTopItemAction';
import { getDailyOrderDatewise } from '../../Redux/Actions/dailyOrderSummaryAction';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { orderDetailGlobalData } from '../../Redux/Actions/OrderDetailsByUserAction';
import { roundValue } from '../../Shared/Constants/Constant';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import { GetAllRemainingBillDetails } from '../../Redux/Actions/RemainingAmountAction';
import PaymentPopup from '../Payment/PaymentPopup';
import { getBillMasterDetails } from '../../Redux/Actions/BillMasterDetailsAction';
import { GetCustomerInfoAboutServices } from '../../Redux/Actions/CustomerServiceInfoAction';

// import { getConfirmOrderDatewise } from '../../Redux/Actions/ConfirmOrdersAction';

function DashboardDate(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2, setValue: setPaymentValue } = useForm();
  const RemainingBillDetail = useSelector(state => state.remainingAmount.remainingAmount)
  const CustomerServiceInfo = useSelector(state => state.customerServiceInfo.customerServiceInfo)

  let [show, setshow] = useState(false)
  let [BillNo, setBillNo] = useState()



  const onSubmit = (data) => {
    props.getFromAndToDate(data)
    dispatch(GetAllRemainingBillDetails(data))
    
  }
  useEffect(() => {
    dispatch(GetAllRemainingBillDetails())
    dispatch(getBillMasterDetails())
    dispatch(GetCustomerInfoAboutServices())
  }, [])

  // const setGlobal = (e) => {
  //   localStorage.setItem('global', e);
  // }

  const checkPayment = (BillNo) =>{
    setBillNo(BillNo)
    setshow(true);
  
  }
 const columns = [
  {
    name: 'Bill No',
    selector: row => row.BillNo,
    minWidth: '100px',
  },
  {
    name: 'Bill Date',
    selector: row => moment(row.BillDate).format('DD-MM-YYYY'),
    minWidth: '110px',
  },
  {
    name: 'Party Name',
    selector: row => row.PartyName,
    minWidth: '120px',
  },
  {
    name: 'Mobile No',
    selector: row => row.Mobile,
    minWidth: '120px',
    maxWidth: '140px',
  },
  {
    name: 'Remaining Amt',
    selector: row => roundValue(row.BillRemainingAmount),
    minWidth: '200px',
    right: true,
  },
  {
    name: 'AddPayment',
    minWidth: '130px',
    selector: (row, i) => <div className='white_space_nowrap'>
      {row.BillRemainingAmount == 0 ?
        <Button variant="outline-success px-3" className='fs-14px' >Done</Button>
        :
        <Button className='btn btn-info fs-12px' onClick={() => checkPayment(row?.BillNo)} >Payment</Button>
      }
    </div>
  }
 ]
 const customerInfoColumn =[
  {
    name: 'Party Name',
    selector: row => row.PartyName,
    minWidth: '150px',
  },
  {
    name: 'Mobile No.',
    selector: row => row.Mobile,
    minWidth: '100px',
  },
  {
    name: 'Vehicle No.',
    selector: row => row.VehicleNo,
    minWidth: '100px',
  },
  {
    name: 'Last Service',
    selector: row => moment(row.BillDate).format("DD-MM-YYYY"),
    minWidth: '110px',
  }

 ];

  const MobileColumns = [
    {
      name: 'Party Name',
      selector: row => row.PartyName,
      minWidth: '100px',
    },
    {
      name: 'Bill No',
      selector: row => row.BillNo,
      minWidth: '100px',
    }
  ]

  const MobileCustomerColumns = [
    {
      name: 'Party Name',
      selector: row => row.PartyName,
      minWidth: '100px',
    },
    {
      name: 'Service Date',
      selector: row => row.BillDate,
      minWidth: '100px',
    }
  ]

  const ExpandedComponent = () => ({ data }) => (
    <div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Party Name:</div>
        <div className='ms-2 '>{data.BillNo}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Bill Date :</div>
        <div className='ms-2 '>{data.BillDate}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Party Name :</div>
        <div className='ms-2 '>{data.PartyName}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Mobile No. :</div>
        <div className='ms-2 '>{data.Mobile}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Remaining :</div>
        <div className='ms-2 '>{data.BillRemainingAmount}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Remaining :</div>
        <div className='white_space_nowrap ms-2'>
          {data.BillRemainingAmount == 0 ?
            <Button variant="btn outline-success px-3" className='fs-14px' >Done</Button>
            :
            <Button className='btn btn-info' onClick={() => checkPayment(data?.BillNo)} >Payment</Button>
          }
        </div>
      </div>
    </div>
  );

  const ExpandedCustomerComponent = () => ({ data }) => (
    <div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Party Name:</div>
        <div className='ms-2 '>{data.PartyName}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Service Date:</div>
        <div className='ms-2 '>{moment(data.BillDate).format("DD-MM-YYYY")}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Vehicle No:</div>
        <div className='ms-2 '>{data.VehicleNo}</div>
      </div>
      <div className='d-flex align-items-center p-2 ps-5'>
        <div className='fw-bold'>Mobile No. :</div>
        <div className='ms-2 '>{data.Mobile}</div>
      </div>
      
      
    </div>
  );


  return (
    <>
      <Row className='justify-content-start align-items-center p-0'>
        <Col xxl={2} xl={4}>
          <label className='form_label text-muted'>From Date:</label>
          <input type="date" className='form_input dash_date'  {...register("FromDate")} />
        </Col>
        <Col xxl={2} xl={4}>
          <label className='form_label text-muted'>To Date:</label>
          <input type="date" className='form_input dash_date'  {...register("ToDate")} />
        </Col>
        <Col xxl={2} xl={4}>
          <label className='form_label text-muted invisible'>'</label>
          <button className='form_input button_dashboard w-auto' onClick={handleSubmit(onSubmit)}>
            Submit
          </button>
        </Col>
        <Col xxl={1} >
        </Col>
      </Row>
      <Row className='p-3 pt-0 m-0 mt-3 bg-white'>
        <Tabs
          defaultActiveKey="remaining amount"
          id="tabbed-view"
          className="mt-3 flex-nowrap text-nowrap" style={{overflowY: 'hidden' , overflowX:'auto'}}
          transition={false}
        >
          <Tab eventKey="remaining amount" title="Remaining Amount" className='text-dark'>
            <Col xxl={12} xl={12} lg={12} md={12} className='mt-4'>
              <h2>Remaining Amount</h2>
              <CustomDataTable columns={columns} MobileColumns={MobileColumns} data={RemainingBillDetail} ExpandedComponent={ExpandedComponent} />
            </Col>
          </Tab>
          <Tab eventKey="Customer Service Info" title="Customer Service Info" >
            <Col xxl={12} xl={12} lg={12} md={12} className='mt-4'>
              <h2>Customer Service Info</h2>
              <CustomDataTable columns={customerInfoColumn} MobileColumns={MobileCustomerColumns} data={CustomerServiceInfo} ExpandedComponent={ExpandedCustomerComponent} />
            </Col>
          </Tab>
        </Tabs>
      </Row>
      <PaymentPopup state={{ show, setshow, BillNo, setBillNo }} />
    </>
  )
}

export default DashboardDate