import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from '../../Pages/Login/Login'
import { getAuthLoginUser, getAuthToken } from '../../Services/AuthService/AuthService'
import Dashboard from '../../Pages/Dashboard/Dashboard'
import Home from '../../Pages/Home/Home'
import Page404 from '../../Pages/Page404/Page404'
import ItemMaster from '../../Pages/ItemMaster/ItemMaster'
import UserMaster from '../../Pages/User Master/UserMaster'
import BillMaster from '../../Pages/BillMaster/BillMaster'
import PaymentGrid from '../../Pages/Payment/PaymentGrid'
import PartyMaster from '../../Pages/PartyMaster/PartyMaster'
import Help from '../Help/Help'
import CompanyMaster from '../../Pages/CompanyMaster/CompanyMaster'
import SecurityKey from '../../Pages/SecurityKey/SecurityKey'

function Routing() {
  const [loginUser, setloginUser] = useState(getAuthLoginUser())
  const RouteData = () => {
    if (getAuthToken()) {
      return <>
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='/' element={<Dashboard />} >
          <Route path='dashboard' element={<Home />} />
          <Route path='/items' element={<ItemMaster />} />
          <Route path='/bills' element={<BillMaster />} />
          <Route path='/payments' element={<PaymentGrid />} />
          <Route path='/party' element={<PartyMaster />} />
          <Route path='/help' element={<Help />} />
          <Route path='/company' element={<CompanyMaster />} />
          {
            loginUser.userCategoryId == 1 && <Route path='/security' element={<SecurityKey />} />
          }
          

        </Route>
        <Route path='*' element={<Page404 />} />
      </>
    }
    else {
      return <>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={<Login />} ></Route>
        <Route path='*' element={<Page404 />} />
      </>
    }
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          {
            RouteData()
          }
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Routing