import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser, deleteUsers, getAllUsers, updateUser } from '../../Redux/Actions/UserMasterAction';
import { Col, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { confirmationDeletePopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import { getAllCompanies } from '../../Redux/Actions/CompanyMasterAction';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import { getAuthLoginUser } from '../../Services/AuthService/AuthService';

/*eslint eqeqeq: "off"*/
function UserMaster() {
  const [show, setshow] = useState(false)
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [loginUser, setloginUser] = useState(getAuthLoginUser())
  const ALLUsers = useSelector(state => state.user.user);
  const ALLCompany = useSelector(state => state.company.company);
  
  const dispatch = useDispatch();
  const [isAdd, setisAdd] = useState(true)

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(addNewUser(data))
    } else {
      dispatch(updateUser(data))
    }
    reset()
    handleClose()
  };


  const editUser = (UserId) => {
    setisAdd(false)

    handleShow();
    const editData = ALLUsers.find(x => x.UserId === UserId)
    for (let key in editData) {
      setValue(key, editData[key]);
    }
  }
  const deleteUser = (UserId) => {
    confirmationDeletePopup().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUsers(UserId))
        return true
      }
    })
  }

  const handleShow = () => {
    setshow(true)
  }
  const handleClose = () => {
    setshow(false)
    setisAdd(true)
    reset()
  }

  const columns = [
    {
      name: 'User Id',
      selector: row => row.UserId,
      sortable: true,
      width: '110px',
    },
    {
      name: 'User Name',
      selector: row => row.UserName,
      sortable: true,
      wrap: true,
      minWidth: '130px',
    },
    {
      name: 'Actions',
      selector: (row) => <div className='white_space_nowrap' >
        <button className='btn btn-warning py-1 fs-14px' onClick={() => editUser(row.UserId)}>EDIT</button>
        <button className='btn btn-danger py-1 ms-2 fs-14px' onClick={() => deleteUser(row.UserId)}>DELETE</button>
      </div>,
      sortable: true,
      width: '175px'
    },
  ];

  const filterField = [
    {
      displayText: 'Name',
      fieldName: 'UserName',
      type: 'text'
    },
    {
      displayText: 'User Id',
      fieldName: 'UserId',
      type: 'number'
    }
  ]
  return (
    <>
      <div className='dashboard_bg'>
        <Row className='m-0 w-100 mb-2'>
          <Col xs={6} className='p-0'>
            <h2>Users</h2>
          </Col>
          <Col xs={6} className='p-0 text-end'>
            <button className='button_dark py-2 px-4' onClick={handleShow}>Add +</button>
          </Col>
        </Row>

        <Modal size='lg' show={show} fullscreen='md-down' onHide={handleClose} backdrop="static" >
          <Modal.Header closeVariant='white' closeButton className='border-0 black_Background text-white'>
            <Modal.Title>{isAdd ? 'ADD NEW USER' : 'EDIT'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {
                  loginUser.usercategoryId == 1 ?
                    <Col lg={6} >
                      <label className='form_label'>Company:</label>
                      <select name="CompanyId" className='form_input' defaultValue={'DEFAULT'} {...register("CompanyId", { required: true })}>
                        <option value='DEFAULT' disabled>--Select Company--</option>
                        {
                          ALLCompany?.map((x, i) => {
                            return <option key={i} value={x.CompanyId}>{x.CompanyName}</option>
                          })
                        }
                      </select>
                      {errors.CompanyId && <span className='error_message'>Company  is required</span>}
                    </Col> : <></>}
                <Col lg={6}>
                  <label className='form_label'>User Name:</label>
                  <input type="text" className='form_input' autoComplete="on" {...register("UserName", { required: true })} />
                  {errors.UserName && <span className='error_message'>UserName is required</span>}
                </Col>
                <Col lg={6} className={isAdd ? '' : 'd-none'}>
                  <label className='form_label'>Password:</label>
                  <input type="password" className='form_input' autoComplete="on" {...register("Password", { required: isAdd ? true : false })} />
                  {isAdd ? errors.Password && <span className='error_message'>Password is required</span> : ''}
                </Col>
              </Row>
            </form>
          </Modal.Body>
          <Modal.Footer className='border-0'>
            <button className='button_dark mt-5 px-5 py-2' onClick={handleSubmit(onSubmit)}>Save</button>
          </Modal.Footer>
        </Modal>

        <CustomDataTable columns={columns} data={ALLUsers} filterField={filterField} />
      </div>
    </>
  )
}

export default UserMaster