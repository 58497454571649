import moment from 'moment';
import React, { memo, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { confirmationPopup, errorPopup, successPopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import { addPayment } from '../../Redux/Actions/PaymentAction';
import { GetAllRemainingBillDetails } from '../../Redux/Actions/RemainingAmountAction';
import { printBillPDF } from '../../Redux/Actions/BillPrint';
import Swal from 'sweetalert2';
import { downloadBillPDF } from '../../Redux/Actions/DownloadBill';

function PaymentPopup({ state }) {
  const { register: register2, handleSubmit: handleSubmit2, getValues,formState: { errors: errors2 }, reset: reset2, setValue: setPaymentValue } = useForm();
  const [showPayment, setshowPayment] = useState(false)
  const allBills = useSelector(state => state.confirmOrders.confirmOrders);
  const dispatch = useDispatch();
  let [valid, setvalid] = useState(true)
  const alldata = useSelector(state => state.confirmOrders.confirmOrders)


  useEffect(() => {
    setshowPayment(state.show)
  }, [state.show])
  
  useEffect(() => {
    if(state.BillNo){
      var order = allBills.find(x => x.BillNo == state?.BillNo)
      setPaymentValue("PaymentDate", moment().format('YYYY-MM-DD'))
      setPaymentValue("BillNo", order?.BillNo)
      setPaymentValue("RemaningAmount", order?.BillRemainingAmount)
      setPaymentValue("PaymentAmount", order?.BillRemainingAmount)
      setPaymentValue("AdjustAmount", 0)
    }
  }, [state.BillNo])



  const handlePaymentClose = () => {
    reset2()
    state.setshow(false)
    state.setBillNo(false)
  }

  const paymentSumbit = async (data) => {
    let index = allBills?.find(x => x.BillNo == data.BillNo)
    data.PaymentAmount = Number(data.PaymentAmount)
    data.AdjustAmount = Number(data.AdjustAmount);

    if (((data.PaymentAmount > 0) && !(data.PaymentAmount > index.BillRemainingAmount)) && (!(data.AdjustAmount < 0) || !(data.AdjustAmount > index.BillAdjustAmount)) && ((data.PaymentAmount + data.AdjustAmount) <= index.BillRemainingAmount)) {
      let message = 'Are you sure to confirm payment?';
      confirmationPopup(message).then((result) => {
        if (result.isConfirmed) {
          dispatch(addPayment(data))
            Swal.fire({
              title: "You can Print/Download your bill.",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Print",
              denyButtonText: `Download`
            }).then((result) => {
              if (result.isConfirmed) {
                  printBillPDF(data.BillNo);
              } else if (result.isDenied) {
                  let isExistData = alldata.find(element => element.BillNo == data.BillNo)
                  downloadBillPDF(data.BillNo, isExistData);
              }
            });
          for (let key in data) {
            setPaymentValue(key, '')
          }
          handlePaymentClose();
          return true
        }
      })
    }
    else {
      let error = "Please check your payment or adjust amount.";
      errorPopup(error)
    }
  }
const setAdjustAmountValue = (data) =>{
  let payment = getValues('PaymentAmount');
  let Remaining = getValues('RemaningAmount');

  if(data.target.checked){
    if(Remaining >= payment){
      valid = true;
      setvalid(valid)
      setPaymentValue("AdjustAmount", Remaining-payment)
    }
    else{
      valid = false;
      setvalid(valid)
    }
  }
}
const checkPaymentAmount = (data) =>{
  let Remaining = getValues('RemaningAmount');
  if(Remaining >= data){
    valid = true;
    setvalid(valid)
  }
  else{
    valid = false;
    setvalid(valid)
  }
};
  return (
    <>
      <Modal size='lg' show={showPayment} fullscreen='md-down' onHide={handlePaymentClose} backdrop="static" >
        <Modal.Header closeVariant='white' closeButton className='border-0 black_Background text-white'>
          <Modal.Title>Payment Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-0'>
          <Row>
            <Col lg={6}>
              <label className='form_label'>BillNo</label>
              <input type="text" className='form_input' autoComplete="on" {...register2("BillNo")} readOnly />
            </Col>

            {/* <Col lg={4}>
              <label className='form_label'>Invoice No</label>
              <input type="text" className='form_input' autoComplete="on" {...register2("InvoiceNo")} readOnly />
            </Col> */}
            <Col lg={6}>
              <label className='form_label'>Date</label>
              <input type="date" className='form_input' autoComplete="on" defaultValue={moment().format('YYYY-MM-DD')} {...register2("PaymentDate", { required: true })} />
              {errors2.PaymentDate && <span className='error_message'>PaymentDate is required</span>}
            </Col>
            <Col lg={5}>
              <label className='form_label'>Remaining Amount</label>
              <input type="number" min={0} className='form_input' autoComplete="on" {...register2("RemaningAmount")} readOnly />
            </Col>
            <Col lg={5}>
              <label className='form_label'>Payment Amount</label>
              <input type="number" min={0} className='form_input' autoComplete="on" {...register2("PaymentAmount", { required: true })}  onChange={(e) => checkPaymentAmount(e.target.value)}/>
              {errors2.PaymentAmount && <span className='error_message'>PaymentAmount is required</span>}
              {valid ? '' :<span className='error_message'>Payment amount is not more than Remaining amount.</span>}

            </Col>
            <Col lg={2}>
            <label className='form_label'>AutoAdjust</label>
              <input type="checkbox" style={{width:'25px', height : '25px'}} className='mt-2' autoComplete="on"  onChange={setAdjustAmountValue}/>
            </Col>
            <Col lg={5}>
              <label className='form_label'>Adjust Amount</label>
              <input type="number" min={0} className='form_input' autoComplete="on" {...register2("AdjustAmount")} />
            </Col>
            <Col lg={12}>
              <label className='form_label'>Remark</label>
              <input type="text" className='form_input' autoComplete="on" {...register2("Remark")} />
            </Col>
            <Col lg={12}>
              <button className='btn btn-success px-5 py-2 my-3' onClick={handleSubmit2(paymentSumbit)}>Save</button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default memo(PaymentPopup)