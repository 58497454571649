export const ImagePath = 'https://api-workshop.softclues.in/StaticFiles/'
export const isMobileScreen = () => {
    if(window.innerWidth < 600){
        return true
    }else{
        return false
    }
}
export const Capitalize = (value) => {
    const words = value?.split(" ");

    for (let i = 0; i < words?.length; i++) {
        words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
    }
    return words?.join(' ')
}
export const dashboardWidth = (value) => {
    let width = getComputedStyle(document.documentElement).getPropertyValue('--dashboard-width')
    if (value) {
        if (width == '250px') {
            document.documentElement.style.setProperty('--dashboard-width', '100px')
            document.documentElement.style.setProperty('--sidebar-width', '100px')
            document.documentElement.style.setProperty('--content-width', 'calc(100% - 100px)')
        } else {
            document.documentElement.style.setProperty('--dashboard-width', '250px')
            document.documentElement.style.setProperty('--sidebar-width', '250px')
            document.documentElement.style.setProperty('--content-width', 'calc(100% - 250px)')
        }
    }
    return width == '250px' ? true : false;
}

export const calculateSum = (lst, field) => {
    if(lst.length > 0){
        return lst.map(o => o[field]).reduce((a, c) => { return Number(a) + Number(c) })
    }
    return 0
}

export const tableCustomStyles = {
    headRow: {
        style: {
            fontWeight: 'bold',
            color: 'white',
            background: 'radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)',
            minHeight: isMobileScreen() ? '40px' :'50px',
        },
    },
    rows: {
        style: {
            minHeight: isMobileScreen() ? '30px' :'40px',
            padding: "0",
        }
    },
    pagination: {
        style: {
            minHeight: '10px'
        }
    }
}
export const tableCustomStyles2 = {
    headRow: {
        style: {
            minHeight: isMobileScreen() ? '40px' :'50px',
            fontWeight: 'bold',
            color: 'white',
            background: 'radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)',
        },
    },
    rows: {
        style: {
            minHeight: isMobileScreen() ? '30px' :'40px',
        }
    },
    pagination: {
        style: {
            minHeight: '10px'
        }
    }
}

export const conditionalRowStyles = [
    {
        when: rows => rows.PartyName == "Order Summary",
        style: {
            backgroundColor: 'lightgray',
            color: 'black',
            fontWeight: 'bold',
            minHeight: isMobileScreen() ? '30px' :'40px',
        },
        headRow: {
            style: {
                fontWeight: 'bold',
                color: 'white',
                minHeight: isMobileScreen() ? '40px' :'50px',
                background: 'radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)',
            },
        },
        pagination: {
            style: {
                minHeight: '10px'
            }
        }
    },
    {
        when: rows => rows.PartyName != "Order Summary",
        style: {
            backgroundColor: '',
            minHeight: isMobileScreen() ? '30px' :'40px',
        },
        headRow: {
            style: {
                fontWeight: 'bold',
                color: 'white',
                minHeight: isMobileScreen() ? '40px' :'50px',
                background: 'radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%)',
            },
        },
        pagination: {
            style: {
                minHeight: '10px'
            }
        }
    },
];

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const DateSplit = (date) => {
    if (date) {
        return date.split('T')[0]
    }
}
export const roundValue = (data) => {
    return (Math.round((data + Number.EPSILON) * 100) / 100).toFixed(2)
}

export const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        const aField = selector(rowA)
        const bField = selector(rowB)
        let comparison = 0;
        if (isNaN(aField)) {
            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }
        }
        else {
            if (parseFloat(aField) > parseFloat(bField)) {
                comparison = 1;
            } else if (parseFloat(aField) < parseFloat(bField)) {
                comparison = -1;
            }
        }
        return direction === 'desc' ? comparison * -1 : comparison;
    });
};
