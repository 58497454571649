import { GET_ALL_COMPANY } from "../Types/Type"

let initialState = {
    allCompany : []
}

export const getAllCompaniesReducer = (state = initialState , action) =>{
    switch(action.type){
        case  GET_ALL_COMPANY: 
            return {
                allCompany : action.data
            };
        default : 
            return state;
    }
}