import { useEffect, useState } from 'react';
import 'animate.css';
import './App.css';
import Routing from './Components/Routing/Routing';
import { getAuthLoginUser, getAuthToken } from './Services/AuthService/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Components/Loader/Loader';
import { getAllUsers } from './Redux/Actions/UserMasterAction';
import { getAllCompanies } from './Redux/Actions/CompanyMasterAction';
import { getAllItems } from './Redux/Actions/ItemMasterAction';
import { getAllOrderDetails } from './Redux/Actions/OrderDetailsByUserAction';
import moment from 'moment';
import { getCountDetail } from './Redux/Actions/GetTotalOrderCountAction';
import { getTopUserDetail } from './Redux/Actions/GetTopUserAction';
import { getTopItemDetail } from './Redux/Actions/GetTopItemAction';
import { getDailyOrder } from './Redux/Actions/dailyOrderSummaryAction';
import States from './States';
import { getAllParty } from './Redux/Actions/AllPartyAction';
import { getBillMasterDetails } from './Redux/Actions/BillMasterDetailsAction';
import { GetAllRemainingBillDetails } from './Redux/Actions/RemainingAmountAction';
import { getAgrDetail } from './Redux/Actions/AGRmasterAction';
import InternetConnection from './Pages/InternetConnection/InternetConnection';
import OpenImage from './Components/OpenImage/OpenImage';

function App() {
  const dispatch = useDispatch()
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    let token = getAuthToken();
    if (token) {
      dispatch(getAllUsers())
      dispatch(getAllItems())
      dispatch(getAllParty())
      dispatch(GetAllRemainingBillDetails())
      dispatch(getAgrDetail())
      // dispatch(getBillMasterDetails(getAuthLoginUser()))
      dispatch(getAllCompanies())

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <>
      {isOnline ? 
        <>
          <Loader />
          <Routing />
          <OpenImage />
        </> : <InternetConnection />}

    </>
  );
}

export default App;
