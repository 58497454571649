import React from 'react'
import './FooterComponent.css'

export const FooterComponent = () => {
  return (
    <>
        <div className="text-center footerDiv">
            © {new Date().getFullYear()} Copyright: <b className='ms-1'>
                <a href="https://www.softclues.in/" target='_blank' className='text-dark'>Softclues Solutions</a>
            </b>
        </div>

    </>
  )
}
