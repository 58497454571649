export const GET_ITEM = 'GET_ITEM';

export const GET_MENU_ITEM = 'GET_MENU_ITEM';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';


export const GET_ORDER_BY_TRNNO = 'GET_ORDER_BY_TRNNO';

export const GET_USER = 'GET_USER';

export const GET_USER_CATEGORY = 'GET_USER_CATEGORY';

export const USER_WISE_ITEM = 'USER_WISE_ITEM';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';

export const CONFIRM_ORDERS = 'CONFIRM_ORDERS';

export const GET_DAILY_ORDER = 'GET_DAILY_ORDER';

export const GET_TOTAL_ORDER_COUNT = 'GET_TOTAL_ORDER_COUNT';

export const GET_TOP_USER = 'GET_TOP_USER';

export const GET_TOP_ITEM = 'GET_TOP_ITEM';

export const PAYMENT_DETAIL = 'PAYMENT_DETAIL';

export const PARTY_MASTER = 'PARTY_MASTER';

export const AGR_MASTER = 'AGR_MASTER';

export const PARTY_CODE = 'PARTY_CODE';

export const USER_WISE_PARTY = 'USER_WISE_PARTY';

export const ALL_PARTY = 'ALL_PARTY';

export const REMAINING_AMOUNT = 'REMAINING_AMOUNT';

export const CUSTOMER_INFO = 'CUSTOMER_INFO';




