import React from 'react'
import './HeaderComponent.css'
import { PiUserCircleLight } from 'react-icons/pi'
import { Capitalize } from '../../Shared/Constants/Constant'
import { getAuthLoginUser } from '../../Services/AuthService/AuthService'
import { successPopup } from '../../Shared/Constants/PopupConstant/PopupContant'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

function HeaderComponent() {
    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                successPopup('You are logout.')
                setTimeout(() => {
                    window.location.href = '/';
                }, 700);
            }
        })
    }
    return (
        <>
            <div className="text-center headerDiv">
                <div className='d-flex align-items-center'>
                    <div className='name_avtar'>{Capitalize(getAuthLoginUser()?.username?.charAt(0))}</div>
                    <span className='ms-2'>{Capitalize(getAuthLoginUser()?.username)}</span>
                </div>
                <div className="d-flex h-100 p-2 py-3"><div className="vr"></div></div>
                <div className='custom-tooltip'>
                    <Link to="/company" className='text-black'>
                        <PiUserCircleLight size={30} className='cursor_pointer tooltip_hover' />
                        <span className='tooltip_text'>Company Setting</span>
                    </Link>
                </div>
                
                <div className="d-flex h-100 p-2 py-3"><div className="vr"></div></div>
                <button className='btn btn-outline-danger' onClick={logout}>Logout</button>
            </div>
        </>
    )
}

export default HeaderComponent