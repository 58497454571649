import { HTTP } from "../../HTTP/HTTP"
import { GET_ALL_COMPANY} from "../Types/Type"
import { errorPopup, successPopup } from "../../Shared/Constants/PopupConstant/PopupContant"
import { getAuthLoginUser } from "../../Services/AuthService/AuthService"
import { GET_ALL_COMPANIES, UPDATE_COMPANY_SECURITY_KEY } from "../../Shared/Constants/ApiConstant"

export const getAllCompaniesDetails = () => {
    let userData = getAuthLoginUser();
    return async (dispatch) => {
        const response = await HTTP.get(`${GET_ALL_COMPANIES}`);
        dispatch(getAllCompany(response?.data?.Data))
    }
}

export const updateSecurityKeyCompany = (value) => {
    let userData = getAuthLoginUser();
    value.UserId = userData.userId;
    delete value.selectInput
    return async (dispatch) => {
        let response = await HTTP.put( UPDATE_COMPANY_SECURITY_KEY, value)
        dispatch(getAllCompaniesDetails())
        if(response.data.StatusCode == 200){
            successPopup('Company key successfully updated')
        }
        else{
            errorPopup(response.data.Message)
        }
    }
}

const getAllCompany = (data) => {
    return {
        type : GET_ALL_COMPANY,
        data : data
    }
}