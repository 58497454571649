import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap'
import './BillMaster.css'
import { useDispatch, useSelector } from 'react-redux';
import { DateSplit, calculateSum } from '../../Shared/Constants/Constant';
import { RiDeleteBin6Line } from 'react-icons/ri'
import { getAuthLoginUser } from '../../Services/AuthService/AuthService';
import { addOrderByTrno, deleteOrderByTrno, getItemPermission, getBillByTrno, getPartypermission, setBlankOrder, getOrder } from '../../Redux/Actions/billMasterAction';
import { confirmationDeletePopup, confirmationPopup, successPopup } from '../../Shared/Constants/PopupConstant/PopupContant';
import moment from 'moment';
import CustomDataTable from '../../Components/CustomDataTable/CustomDataTable';
import { getBillMasterDetails } from '../../Redux/Actions/BillMasterDetailsAction';
import { roundValue } from '../../Shared/Constants/Constant';
import CreatableSelect from 'react-select/creatable';
import { getAllParty } from '../../Redux/Actions/AllPartyAction';
import PaymentPopup from '../Payment/PaymentPopup';
import { HTTP } from '../../HTTP/HTTP';
import { INSERT_DETAIL } from '../../Shared/Constants/ApiConstant';
import { getAllItems } from '../../Redux/Actions/ItemMasterAction';
import { GetAllRemainingBillDetails } from '../../Redux/Actions/RemainingAmountAction';
import {getAllCompanies} from '../../Redux/Actions/CompanyMasterAction';
import Swal from 'sweetalert2';
import { printBillPDF } from '../../Redux/Actions/BillPrint';
import { downloadBillPDF } from '../../Redux/Actions/DownloadBill';
import { useLocation, useNavigate } from 'react-router-dom';

/* eslint eqeqeq: 0 */
function BillMaster() {
    const [show, setshow] = useState(false)
    const AllConfirmOrders = useSelector(state => state.confirmOrders.confirmOrders);
    const formBlankData = { BillNo: 0, SeqNo: 0, BillDate: "", ItemId: "",Labour : false, Pcs: "", Rate: "", Amount: "", CompanyId: "", AcYear: "", UserId: "" , selectedItemOption : null }
    const OrderByTrn = useSelector(state => state.orders.orders)
    const dispatch = useDispatch()
    let [formRowsData, setformRowsData] = useState([{ ...formBlankData }])
    const alldata = useSelector(state => state.confirmOrders.confirmOrders)
    let [tableError, settableError] = useState([[]])
    let [isBillDate, setisBillDate] = useState(false)
    let [isBillNo, setisBillNo] = useState(0)
    let [vehicle, setvehicle] = useState()
    let [isSelected, setisSelected] = useState()
    let [totalOrderData, settotalOrderData] = useState({ Pcs: 0, Rate: 0, Amount: 0 })
    let [loginUser, setloginUser] = useState(getAuthLoginUser())
    let itemforAdmin = useSelector(state => state.items.items)
    let [AllItems, setAllItems] = useState([])
    let [PartyCode, setPartyCode] = useState(false)
    let [CarCompanyModelName, setCarCompanyModelName] = useState()
    let [PaymentDate, setPaymentDate] = useState(false)
    let [oldAddedData, setoldAddedData] = useState([])
    let [selectedOptions, setSelectedOptions] = useState();
    const allParty = useSelector(state => state.allParty.allParty)
    const [options, setoptions] = useState([])
    let [optionList, setoptionList] = useState([])
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    let [BillNo, setBillNo] = useState()
    let [paymentShow, setpaymentShow] = useState(false)
    let [paymentRemaining, setpaymentRemaining] = useState(true)
    let [ItemOptions, setItemOptions] = useState([])
    let [ItemOptionList, setItemOptionList] = useState([])
    let [selectedItemOption, setSelectedItemOption] = useState();
    let [isGst, setisGst] = useState(false)
    const AllCompanies = useSelector(state => state.company.company);
    let [payableAmount, setpayableAmount] = useState(0)
    let [CGST, setCGST] = useState()
    let [SGST, setSGST] = useState()
    const location = useLocation();
    let [locationState, setlocationState] = useState(location.state)

    useEffect(() => {
        if(locationState && AllConfirmOrders.length){
            if(locationState.BillNo != null && locationState.PartyCode != null){
                getItems(locationState.BillNo , locationState.PartyCode)
            }
        }
    }, [AllConfirmOrders])
    useEffect(() => {
        resetComponentOnRefresh()
    }, [])
    
    const resetComponentOnRefresh = () =>{
        window.history.replaceState({}, '', location.pathname);
    }

    let [isSelectExpand, setisSelectExpand] = useState(0)

    const tableRef = useRef()

    useEffect(() => {
        setoptions([...allParty.map(x => {
            return { value: x.PartyCode, label: x.PartyName }
        })])
        setoptionList([...allParty.map(x => {
            return { value: x.PartyCode, label: x.PartyName }
        })])
    }, [allParty])

    let [CustomerEmail, setCustomerEmail] = useState()
    let [ValidEmail, setValidEmail] = useState()
    let [CustomerMobileNo, setCustomerMobileNo] = useState()
    let [MeterReading, setMeterReading] = useState()
    
    useEffect(() => {
        getItemData()
    }, [itemforAdmin])

    const getItemData = async () => {
        let AllItems = itemforAdmin?.filter(x => x.IsActive ==true)
        setAllItems([...AllItems])
        ItemOptions = [...AllItems.map(x => {
            return { value: x.ItemId, label: x.ItemName }
        })]
        setItemOptions([...ItemOptions])
        ItemOptionList = [...AllItems.map(x => {
            return { value: x.ItemId, label: x.ItemName }
        })]
        setItemOptionList([...ItemOptionList])
    }
    useEffect(() => {
        dispatch(getBillMasterDetails())
        dispatch(setBlankOrder())
        dispatch(getAllCompanies())
    }, [])
    useEffect(() => {
        if (OrderByTrn?.length > 0) {
            formRowsData = [...OrderByTrn, ...oldAddedData];
            formRowsData.forEach((x) => x.selectedItemOption = {value : x.ItemId , label : x.ItemName});
            setformRowsData([...formRowsData])
            setisBillDate(DateSplit(OrderByTrn[0]?.BillDate))
            setvehicle(OrderByTrn[0]?.VehicleNo)
            setPartyCode(OrderByTrn[0]?.PartyCode)
            setCarCompanyModelName(OrderByTrn[0]?.CarCompanyModelName)
            setPaymentDate(DateSplit(OrderByTrn[0]?.PaymentDate))
            setmeterReading(OrderByTrn[0]?.MeterReading)
            if(OrderByTrn[0].CGST && OrderByTrn[0].CGST != 0){
                isGst = true;
                setisGst(isGst)
                CGST = OrderByTrn[0].CGST;
                setCGST(CGST)
                SGST = OrderByTrn[0].SGST;
                setSGST(SGST)
            }
            else{
                isGst = false;
                setisGst(isGst)
                setCGST()
                setSGST()
            }
            setTotalData()
            payableValue(totalOrderData.Amount)
            oldAddedData = [];
            setoldAddedData([...oldAddedData])
            validate()
            existAlertValidate()
        }
        else if (isBillNo == 0) {
            formRowsData = [formBlankData];
            setformRowsData([...formRowsData])
            resetTotalData()
            setisBillDate(moment().format('YYYY-MM-DD'))
            setPartyCode('')
            setPaymentDate(moment().format('YYYY-MM-DD'))
            setCarCompanyModelName()
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            setvehicle()
            setEmail()
            setmobileNumber()
            setmeterReading()
        }
        else {
            formRowsData = [formBlankData];
            setformRowsData([...formRowsData])
            resetTotalData()
            setisBillDate(moment().format('YYYY-MM-DD'))
            setvehicle()
            setPartyCode('')
            setPaymentDate(moment().format('YYYY-MM-DD'))
            setCarCompanyModelName()
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            setEmail()
            setmobileNumber()
            setmeterReading()
            // isBillNo = ''
            // setisBillNo(isBillNo)
        }
    }, [OrderByTrn])


    let validateItem = ['ItemId', 'Pcs', 'Rate'];
    const onSubmit = async (data) => {
        formRowsData.forEach((x) => x.CompanyId = getAuthLoginUser().companyId);
        formRowsData.forEach((x) => x.UserId = getAuthLoginUser().userId);
        formRowsData.forEach((x) => x.AcYear = getAuthLoginUser().acYear);
        formRowsData.forEach((x) => x.VehicleNo = vehicle);
        formRowsData.forEach((x) => x.PartyCode = PartyCode ?? "");
        formRowsData.forEach((x) => x.CarCompanyModelName = CarCompanyModelName);
        formRowsData.forEach((x) => x.PaymentDate = PaymentDate);
        formRowsData.forEach((x) => x.PartyName = selectedOptions?.label);
        formRowsData.forEach((x) => x.CustomerEmail = CustomerEmail);
        formRowsData.forEach((x) => x.CustomerMobileNo = CustomerMobileNo);
        formRowsData.forEach((x) => x.MeterReading = Number(MeterReading));
        formRowsData.forEach((x) => x.CGST = Number(CGST));
        formRowsData.forEach((x) => x.SGST = Number(SGST));
        formRowsData.forEach((x) => x.PayableAmount = Number(payableAmount));
        formRowsData.forEach((x) => x.Rate = x.Rate == '' ? 0 : x.Rate);
        formRowsData.forEach((x) => x.Pcs = x.Pcs == '' ? 0 : x.Pcs);

        setformRowsData([...formRowsData])
        console.log('formRowsData', formRowsData)
        if (isBillDate && isBillDate != '') {
            formRowsData.forEach((x) => x.BillDate = isBillDate);
        } else {
            setisBillDate()
        }
        if (PartyCode  == undefined) {
            PartyCode = ""
            setPartyCode(PartyCode)
        }
        validate()
        if(vehicle == undefined){
            vehicle = '';
            setvehicle(vehicle)
        }
        if(CarCompanyModelName == undefined){
            CarCompanyModelName = '';
            setCarCompanyModelName(CarCompanyModelName)
        }
        if(MeterReading == undefined){
            setmeterReading('')
        }
        if(CustomerEmail == undefined){
            setEmail('')
        }else{
            checkEmailValid()
        }
        if(CustomerMobileNo == undefined){
            setmobileNumber('')
        }
        if(selectedOptions == undefined){
            selectedOptions= {value : "" , label : ""}
            setSelectedOptions(selectedOptions)
        }
        settableError([...tableError])
        let isValid = tableError.filter(x => x.length)
        if (
            isValid.length == 0 && 
            CarCompanyModelName != '' &&
            MeterReading != '' &&
            CustomerMobileNo != '' &&
            selectedOptions.value != "" &&
            vehicle != '' &&
            isBillDate &&   
            existAlertValidate()
            ) {
                formRowsData.forEach((x) => x.BillNo = isBillNo == 0 ? "" : isBillNo);
                formRowsData.forEach((x) => x.ItemId = x.ItemId == "" ? 0 : x.ItemId);
            setformRowsData([...formRowsData]);
            const response = await HTTP.post(INSERT_DETAIL, formRowsData)

            // debugger

            if (response) {
                dispatch(getOrder([]))
                dispatch(getAllParty())
                dispatch(getAllItems())
                if (response?.data?.BillNo) {
                    dispatch(getBillMasterDetails())
                    dispatch(GetAllRemainingBillDetails())
                    confirmationPopup("Do you Received any payment ?").then((result) => {
                        if (result.isConfirmed) {
                            BillNo = response.data.BillNo;
                            setBillNo(BillNo)
                            paymentShow = true;
                            setpaymentShow(paymentShow)                           
                        }
                        else{                    
                            Swal.fire({
                                title: "You can Print/Download your bill.",
                                showDenyButton: true,
                                showCancelButton: true,
                                confirmButtonText: "Print",
                                denyButtonText: `Download`
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    printBillPDF(response?.data?.BillNo);
                                } else if (result.isDenied) {
                                    let isExistData = alldata.find(element => element.BillNo == response?.data?.BillNo)
                                    downloadBillPDF(response?.data?.BillNo, isExistData);
                                }
                              });
                        }
                    })
                }
            }
            setisBillDate(moment().format('YYYY-MM-DD'))
            setisBillNo(0)
            setvehicle()
            setPartyCode('')
            setEmail()
            setisGst(false)
            setmobileNumber()
            setmeterReading()
            setPaymentDate(moment().format('YYYY-MM-DD'))
            setCarCompanyModelName()
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            resetComponent()
            setlocationState({})          
        }

    };
    const resetComponent = () =>{
        formRowsData = [formBlankData];
            setformRowsData([...formRowsData])
            resetTotalData()
            setisBillDate(moment().format('YYYY-MM-DD'))
            setvehicle()
            setPartyCode('')
            setPaymentDate(moment().format('YYYY-MM-DD'))
            setCarCompanyModelName()
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            setEmail()
            setmobileNumber()
            setmeterReading()
            isBillNo = 0
            setisBillNo(isBillNo)
            tableError = []
             settableError([...tableError])
            isSelected = null
            setisSelected(isSelected)
            paymentRemaining = true;
            setpaymentRemaining(paymentRemaining)
            setCGST()
            setSGST()
            setpayableAmount()
            setisGst(false)
    }

    const checkEmailValid = () => {
        if(CustomerEmail && CustomerEmail != '' && !emailRegex.test(CustomerEmail)){
            ValidEmail = true;
            setValidEmail(ValidEmail)
           }else{
            ValidEmail = false;
            setValidEmail(ValidEmail)
           }
    }

    const setTotalData = () => {
        totalOrderData.Amount = calculateSum(formRowsData, 'Amount');
        totalOrderData.Rate = calculateSum(formRowsData, 'Rate');
        totalOrderData.Pcs = calculateSum(formRowsData, 'Pcs');
        settotalOrderData({ ...totalOrderData })
    }
    const resetTotalData = () => {
        totalOrderData.Amount = 0;
        totalOrderData.Rate = 0;
        totalOrderData.Pcs = 0;
        settotalOrderData({ ...totalOrderData })
    }
    const existAlertValidate = () => {
        let existValue = formRowsData.map(x => x.selectedItemOption?.value)
        existValue = existValue.filter((item, index) => existValue.indexOf(item) !== index)
        existValue = AllItems.filter((x) => existValue.includes(Number(x.ItemId)))
        if (existValue.length > 0) {
            let html = <Alert variant='danger' className='p-2 mt-3'>
                {existValue.map(x => x.ItemName)?.join(', ')} already selected.
            </Alert>
            setisSelected(html)
            return false
        } else {
            setisSelected()
            return true
        }
    }
    const validate = () => {
        validateItem.forEach((x) => {
            formRowsData.forEach((y, index) => {
                if (y[x] == '' || y[x] == 0 || y[x] == null || y[x] == undefined) {
                    tableError[index] ? tableError[index].push(x) : tableError[index] = [x]
                } else {
                    tableError[index] = tableError[index]?.filter((c) => c != x)
                }

                if(y.Labour){ 
                    tableError[index] = tableError[index]?.filter((c) => {
                        if(c == 'Pcs' || c == 'Rate'){
                            return false
                        }
                        return true
                    })
                }
                if(y['selectedItemOption'] && y['selectedItemOption']['value'] == ''){
                    tableError[index] = tableError[index]?.filter((c) => c != 'ItemId')
                }
                tableError[index] = [...new Set(tableError[index])]
            })
        })
        settableError([...tableError])
        tableRef.current.style.height = 'auto';
    }
    const getValue = (e, index) => {
        if (e.name == 'ItemId') {
            let rowItem = AllItems.find((x) => x.ItemId == Number(e.value))
            if(rowItem){
                formRowsData[index].Rate = rowItem.ItemRate;
                formRowsData[index].ItemName = rowItem.ItemName;
                formRowsData[index][e.name] = Number(e.value);
                formRowsData[index].Amount = formRowsData[index].Rate * formRowsData[index].Pcs;
                formRowsData[index].selectedItemOption = {value : e.value , label : e.label};
                formRowsData[index].Labour = rowItem.Labour;
                if(rowItem.Labour){
                    formRowsData[index].Rate = '';
                    formRowsData[index].Pcs = '';
                    formRowsData[index].Amount = '';
                }
            }
        }
        else if (e.target.name == 'Rate') {
            let decimalValue = e.target.value.split('.');
            if(decimalValue.length < 2 || decimalValue[1].length < 3){
                formRowsData[index].Rate = Number(Math.abs(e.target.value));
                formRowsData[index].Amount = formRowsData[index].Rate * formRowsData[index].Pcs;
            }
        }
        else if (e.target.name == 'Pcs') {
            formRowsData[index].Amount = formRowsData[index].Rate * e.target.value;
            formRowsData[index][e.target.name] = Number(Math.abs(e.target.value));
        }
        else if(e.target.name == 'Labour'){
            ItemOptionList = ItemOptionList.filter(x => x.value != '')
            setItemOptionList([...ItemOptionList])
            if(formRowsData[index].ItemId == 0){
                formRowsData[index][e.target.name] = e.target.checked;
                if(e.target.checked){
                    formRowsData[index].Rate = '';
                    formRowsData[index].Pcs = '';
                }
            }
        }
        else if(e.target.name == 'Amount'){
            let decimalValue = e.target.value.split('.');
            if(decimalValue.length < 2 || decimalValue[1].length < 3){
                formRowsData[index][e.target.name] = Number(Math.abs(e.target.value));
                if(formRowsData[index].Pcs != 0){
                    formRowsData[index].Rate = Number(roundValue(formRowsData[index][e.target.name] / formRowsData[index].Pcs)) ;
                }
            }
        }
        else {
            formRowsData[index][e.target.name] = e.target.value;
        }
        setformRowsData([...formRowsData])
        setTotalData()
        setpayableAmount(totalOrderData.Amount)

        payableValue(totalOrderData.Amount)
        validate()
        existAlertValidate()
    }
    const getTrnNoValue = (e) => {
        settableError([[]])
        setisBillNo(Number(e.target.value));
        if (e.target.value != '' && e.target.value > 0) {
            dispatch(getBillByTrno(e.target.value))
            let order = AllConfirmOrders.find(x => x.BillNo == e.target.value)
            checkOrderPaymentStatus(order);
            if (order) {
                setvehicle(order?.VehicleNo)
                setCarCompanyModelName(order?.CarCompanyModelName)
                let existParty = allParty.find(x => x.PartyCode == order?.PartyCode)
                if (existParty) {
                    handleSelect({ value: order?.PartyCode, label: existParty.PartyName })
                } else {
                    selectedOptions = null;
                    setSelectedOptions(selectedOptions)
                    setEmail('')
                    setmobileNumber('')
                    setmeterReading('')
                }
            }
            else {
                setformRowsData([{ ...formBlankData }])
                setisBillDate(moment().format('YYYY-MM-DD'))
                setvehicle('')
                setPartyCode('')
                setPaymentDate(moment().format('YYYY-MM-DD'))
                setCarCompanyModelName('')
                resetTotalData()
            }
        }
        else {
            setformRowsData([{ ...formBlankData }])
            setisBillDate(moment().format('YYYY-MM-DD'))
            setvehicle()
            setPartyCode('')
            setPaymentDate(moment().format('YYYY-MM-DD'))
            setCarCompanyModelName()
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            setEmail()
            setmobileNumber()
            setmeterReading()
            resetTotalData()
        }
    }
    const addNewRow = () => {
        formRowsData.push(formBlankData)
        setformRowsData([...formRowsData])
        tableRef.current.style.height = 'auto';
    }
    const removeItem = (index) => {
        confirmationDeletePopup().then((result) => {
            if (result.isConfirmed) {
                let record = formRowsData[index];
                if (record.BillNo > 0) {
                    dispatch(deleteOrderByTrno(record, isBillNo))
                    oldAddedData = formRowsData.filter(x => x.SeqNo == 0)
                    setoldAddedData([...oldAddedData])
                }
                else {
                    formRowsData.splice(index, 1)
                    setformRowsData([...formRowsData])
                    setpayableAmount(totalOrderData.Amount)
                    payableValue(totalOrderData.Amount)

                }
                if (formRowsData.length == 0) {
                    formRowsData = [formBlankData];
                    setformRowsData([...formRowsData])
                }
            }
        }).then(() => {
            tableRef.current.style.height = 'auto';
            existAlertValidate()
        })
    }
    const getVehicleNo = (e) => {
        vehicle = e.target.value;
        setvehicle(vehicle)
    }
    // const popupOpen = () => {
    //     dispatch(getBillMasterDetails())
    //     setshow(true)
    // }
    // const handleClose = () => {
    //     setshow(false)
    // }
    // const columns = [
    //     {
    //         name: 'Party Name',
    //         selector: row => row?.PartyName,
    //         sortable: true,
    //         minWidth: '230px',
    //         maxWidth: '250px',
    //         wrap: true,
    //     },
    //     {
    //         name: 'AcYear',
    //         selector: row => row.AcYear,
    //         sortable: true,
    //         minWidth: '110px',
    //         maxWidth: '110px',
    //     },
    //     {
    //         name: 'BillNo',
    //         selector: row => row.BillNo,
    //         right: true,
    //         minWidth: '90px',
    //         maxWidth: '90px',
    //     },
    //     {
    //         name: 'BillDate',
    //         selector: row => moment(row.BillDate).format('DD-MM-YYYY'),
    //         sortable: true,
    //         width: '130px',
    //     },
    //     {
    //         name: 'Pieces',
    //         selector: row => row.TotalPcs,
    //         right: true,
    //         sortable: true,
    //         width: '100px',
    //     },
    //     {
    //         name: 'Rate',
    //         selector: row => roundValue(row?.Rate),
    //         right: true,
    //         sortable: true,
    //         width: '120px',
    //     },
    //     {
    //         name: 'Amount',
    //         selector: row => roundValue(row?.PayableAmount).toLocaleString('en-IN'),
    //         right: true,
    //         sortable: true,
    //         width: '120px',
    //     },
    //     {
    //         name: 'Select',
    //         selector: (row, i) => <div className='white_space_nowrap d-flex align-items-center' style={{ minWidth: '450px' }}>
    //             <input type='checkbox' id={`checkButton${i}`} name='IsOrderConfirmed' onChange={(e) => getItems(row.BillNo, row.PartyCode)} />
    //         </div>,
    //         width: '90px',
    //     }

    // ];
    const getItems = (BillNo, PartyCode) => {
        let order = AllConfirmOrders.find(x => x.BillNo == BillNo)
        checkOrderPaymentStatus(order);
        vehicle = order.VehicleNo;
        setvehicle(vehicle)
        setisBillNo(BillNo)
        dispatch(getBillByTrno(BillNo))
        setPartyCode(order.PartyCode)
        setCarCompanyModelName(order.CarCompanyModelName)
        setshow(false)
        settableError([...tableError])

        let existParty = allParty.find(x => x.PartyCode == PartyCode)
        if (existParty) {
            handleSelect({ value: PartyCode, label: existParty.PartyName })
        } else {
            selectedOptions = null;
            setSelectedOptions(selectedOptions)
            setEmail('')
            setmobileNumber('')
            setmeterReading('')
        }
    }
    const checkOrderPaymentStatus = (order) => {
        if(order&& order.BillRemainingAmount == 0){
            paymentRemaining = false;
            setpaymentRemaining(paymentRemaining)
        }else{
            paymentRemaining = true;
            setpaymentRemaining(paymentRemaining)
        }
    }
    const getCarCompanyModelName = (e) => {
        setCarCompanyModelName(e);
        // const newPaymentDate = moment(isBillDate ?? moment()).add(e, 'days');
        // setPaymentDate(moment(newPaymentDate).format('YYYY-MM-DD'));
    };
    const getPaymentdate = (e) => {
        setPaymentDate(e);
        const enddate = moment(e)
        const startdate = moment(isBillDate ?? moment());
        const diff = enddate.diff(startdate);
        const diffDuration = moment.duration(diff);
        setCarCompanyModelName(diffDuration._data.days)
    }

    function handleSelect(data) {
        selectedOptions = data;
        setSelectedOptions(selectedOptions);
        if(data)
        { 
            let existParty = allParty.find(x => x.PartyCode == data.value)  
            if (existParty) {
                setEmail(existParty.Email)
                setmobileNumber(existParty.Mobile)
                PartyCode = data.value;
                setPartyCode(PartyCode)
            }else{
                setEmail('')
                setmobileNumber('')
            }
        } else {
            setEmail('')
            setmobileNumber('')
        }
    }

    const handleItemCreate = (inputValue , i) => {
        const newOption = { value: '', label: inputValue };
        ItemOptionList.push(newOption)
        setItemOptionList([...ItemOptionList]);
        formRowsData[i].selectedItemOption = newOption;
        formRowsData[i].ItemId = 0;
        formRowsData[i].ItemName = inputValue;
        formRowsData[i].Rate = 0;
        formRowsData[i].Pcs = 0;
        formRowsData[i].Amount = 0;
        setformRowsData([...formRowsData]);
        validate()
    };

    const handlePartyCreate = (inputValue , i) => {
        optionList.push({ value: inputValue, label: inputValue })
        setoptionList([...optionList]);
        selectedOptions = { value: null, label: inputValue };
        setSelectedOptions(selectedOptions);
        setEmail('')
        setmobileNumber('')
    };

    const setEmail = (data) => {
        CustomerEmail = data;
        setCustomerEmail(CustomerEmail)
        checkEmailValid()
    }

    const setmobileNumber = (data) => {
        CustomerMobileNo = data;
        setCustomerMobileNo(CustomerMobileNo)
    }

    const setmeterReading = (data) => {
        MeterReading = data;
        setMeterReading(MeterReading)
    }
    const changeHeight = (val , i) => {
        let lengthArr = formRowsData.map((x,i) => i).slice(-4)
        let tableHeight = tableRef.current.offsetHeight;
        if(val =='open'){
            
            if(lengthArr.includes(i) && isSelectExpand == 0){
                setTimeout(() => {
                    let a = [document.querySelector('.itemSelect__menu').offsetHeight-40]
        
                    for(let index=1; index<=4; index++){
                        if(a[a.length-1] - 65 > 0){
                            a.push(a[a.length-1]-65)
                        }
                        if(i == formRowsData.length - index) {
                            tableRef.current.style.height =  tableHeight + a[index-1] + 'px';
                            isSelectExpand = a[index-1];
                            setisSelectExpand(isSelectExpand)
                        } 
                    }
                }, 0);
            }
        }else{
            if(isSelectExpand > 0){
                tableRef.current.style.height = tableHeight - isSelectExpand + 'px'
                isSelectExpand = 0;
                setisSelectExpand(isSelectExpand)
            }
        }
    }
    
    const handleRadioChange = (e) =>{
        if(e.target.value == 'GST'){
            isGst = true;
            setisGst(isGst)
            CGST = AllCompanies[0]?.Cgst;
            setCGST(CGST)
            SGST = AllCompanies[0]?.Sgst;
            setSGST(SGST)
        }
        else{
            isGst = false;
            setisGst(isGst)
            CGST = null;
            setCGST(CGST)
            SGST = null;
            setSGST(SGST)
        }
        payableValue(totalOrderData.Amount)
    }

    const payableValue = (data) =>{
        if(isGst){
            let sgst = CGST ? Number((CGST/100)) : Number((AllCompanies[0].Sgst/100));
            let cgst = SGST ? Number((SGST/100)): Number((AllCompanies[0].Cgst/100));
            let totalGstAmount = Number(roundValue( Number(roundValue(data + (data *cgst))) + ( data * sgst)));
            payableAmount = totalGstAmount
            setpayableAmount(totalGstAmount)
            return totalGstAmount

        }
        else{
            payableAmount = data
            setpayableAmount(payableAmount)
            return payableAmount
        }
    }
    // const ExpandedComponent = () => ({ data }) => (
    //     <div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Party Name :</div>
    //             <div className='ms-2'>{data.PartyName}</div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Ac Year : </div>
    //             <div className='ms-2'> {data.AcYear}</div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Bill No :</div>
    //             <div className='ms-2'> {data.BillNo} </div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Bill Date :</div>
    //             <div className='ms-2'> {moment(data.BillDate).format('DD-MM-YYYY')} </div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Total Pieces : </div>
    //             <div className='ms-2'> {data.TotalPcs} </div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Rate : </div>
    //             <div className='ms-2'> {roundValue(data?.Rate)} </div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Payable Amount : </div>
    //             <div className='ms-2'> {roundValue(data?.PayableAmount).toLocaleString('en-IN')} </div>
    //         </div>
    //         <div className='d-flex align-items-center p-2 ps-5'>
    //             <div className='fw-bold'>Select : </div>
    //             <div className='ms-2 d-flex align-items-center'>
    //                 <input type='checkbox' name='IsOrderConfirmed' onChange={(e) => getItems(data.BillNo, data.PartyCode)} />
    //             </div>
    //         </div>
    //     </div>
    // );

    // const MobileColumns = [
    //     {
    //         name: 'Party Name',
    //         selector: row => row?.PartyName,
    //         sortable : true,
    //         minWidth: '150px',
    //         maxWidth: '300px',
    //     },
    //     {
    //         name: 'Bill No.',
    //         selector: row => row.BillNo,
    //         right: true,
    //         sortable : true,
    //         minWidth: '150px',
    //         maxWidth: '200px',
    //     },
    // ];
    
    return (
        <>
            <div className='dashboard_bg'>
                <Row className='m-0 w-100'>
                    <Col xs={6} className='p-0'>
                        <h2 className='page_heading'>Bills</h2>
                    </Col>
                </Row>
                
                <div className='order_form_bg'>
                    <form >
                        <Row>
                            {/* <Col xxl={3} lg={5} md={5} sm={5}>
                                <label className='form_label'>Bill No:</label>
                                <input type="number" min={0} className='form_input' name='BillNo' value={isBillNo} onChange={getTrnNoValue} />
                            </Col> */}
                            {/* <div className='col-auto sm-1'>
                                <div className='row m-0'>
                                    <label className='remove-height form_label invisible'>'</label>
                                </div>
                                <div className='row m-0 cursor_pointer'>
                                    <div className='form_input' onClick={() => popupOpen()}>
                                        ...
                                    </div>
                                </div>
                            </div> */}
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>Bill Date:</label>
                                <input type="date" className='form_input' name='BillDate' value={isBillDate ? isBillDate : ''} autoComplete="on" onChange={(e) => setisBillDate(e.target.value)} />
                                {
                                    isBillDate == undefined ? <p className='error_message m-0 p-0'>Transaction date must be required</p> : <></>
                                }
                            </Col>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>Vehicle No:</label>
                                <input type="text" className='form_input' name='VehicleNo' value={vehicle ?? ''} onChange={getVehicleNo} />
                                {
                                    (vehicle != undefined && vehicle == '') ? <p className='error_message m-0 p-0'>Vehicle No must be required</p> : <></>
                                }
                            </Col>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>Car Company Model Name:</label>
                                <input type="text" className='form_input' name='CarCompanyModelName' value={CarCompanyModelName ?? ''} onChange={(e) => getCarCompanyModelName(e.target.value)} min={0} />
                                {
                                    (CarCompanyModelName != undefined && CarCompanyModelName == '') ? <p className='error_message m-0 p-0'>Model Name must be required</p> : <></>
                                }
                            </Col>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>K/M Reading:</label>
                                <input type="number" className='form_input' name='MeterReading' value={MeterReading ?? ''} onChange={(e) => setmeterReading(e.target.value)} min={0} />
                                {
                                    (MeterReading != undefined && MeterReading == '') ? <p className='error_message m-0 p-0'>Meter Reading must be required</p> : <></>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>CustomerName</label>
                                <CreatableSelect
                                    options={optionList}
                                    placeholder="Select Party"
                                    value={selectedOptions}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    name="PartyName"
                                    onCreateOption={handlePartyCreate}
                                />
                                {
                                    (selectedOptions != undefined && selectedOptions?.value == "") ? <p className='error_message m-0 p-0'>Customer Name must be required</p> : <></>
                                }
                            </Col>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>Customer Email :</label>
                                <input type="email" className='form_input' name='CustomerEmail' value={CustomerEmail ?? ''} onChange={(e) => setEmail(e.target.value)} min={0} />
                                {
                                    ValidEmail ? <p className='error_message m-0 p-0'>Customer Email must be Valid</p> : <></>
                                }
                            </Col>
                            <Col xxl={3} lg={6} md={12} sm={12}>
                                <label className='form_label'>Customer Mobile No :</label>
                                <input type="number" className='form_input' name='CustomerMobileNo' value={CustomerMobileNo ?? ''} onChange={(e) => setmobileNumber(e.target.value)} min={0} />
                                {
                                    (CustomerMobileNo != undefined && CustomerMobileNo == '') ? <p className='error_message m-0 p-0'>Customer Mobile No. must be required</p> : <></>
                                }
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col lg={1} md={6} sm={6} className='d-flex align-items-center'>
                                <label className='form_label d-inline-block'>GST:</label>
                                <input type="radio" style={{width:'20px', height : '20px'}} className='ms-2 mt-2' autoComplete="on" checked={isGst} name='GST' value='GST' onChange={handleRadioChange} />
                            </Col>
                            <Col lg={2} md={6} sm={6} className='d-flex align-items-center'>
                                <label className='form_label d-inline-block'>Non GST:</label>
                                <input type="radio" style={{width:'20px', height : '20px'}} className='ms-2 mt-2' autoComplete="on" name='GST' value='Non GST' checked={!isGst} onChange={handleRadioChange} />
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                            </Col>
                            <Col lg={3} className='d-flex align-items-center'>
                                {
                                    paymentRemaining ?
                                        <button type='button' className='btn btn-success px-4 mt-3' onClick={onSubmit}>Save</button>
                                    : <></>
                                }
                                <span className='btn btn-danger mt-3 ms-3' onClick={resetComponent}>Reset</span>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12} className='mt-3'>
                                <div className='table-scroll' ref={tableRef} style={{ overflowX: 'scroll' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Sequence No.</th>
                                                <th>Item</th>
                                                <th>Labour</th>
                                                <th>Piece</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formRowsData?.length > 0 ?
                                                    formRowsData?.map((x, i) => {
                                                        return <tr key={i}>
                                                            <td className='d-none'><input type="number" min={0} readOnly name="BillNo" className='form_input pointer_none text-end' onChange={(e) => getValue(e, i)} /></td>
                                                            <td><input type="number" min={0} name="SeqNo" readOnly value={x.SeqNo} className='form_input pointer_none' onChange={(e) => getValue(e, i)} /></td>
                                                            <td style={{maxwidth:'280px' , minWidth : '200px'}}>
                                                                <CreatableSelect
                                                                    classNamePrefix='itemSelect'
                                                                    options={ItemOptionList}
                                                                    placeholder="Select Item"
                                                                    value={x.selectedItemOption}
                                                                    onChange={(e) => getValue({...e , name : 'ItemId'}, i)}
                                                                    isSearchable={true}
                                                                    name="ItemName"
                                                                    onCreateOption={(e) => handleItemCreate(e,i)}
                                                                    onMenuOpen={() => changeHeight('open' , i)}
                                                                    onMenuClose={() => changeHeight('close' , i)}
                                                                />
                                                                {
                                                                    tableError[i]?.includes('ItemId') ? <p className='error_message m-0 p-0'>Item must be required</p> : <></>
                                                                }
                                                            </td>
                                                            <td className='text-center'>
                                                                    <input type="checkbox" name='Labour' style={{width:'25px',height:'25px',marginTop:'10px', accentColor:'var(--color-orange)'}} checked={x.Labour} onChange={(e) => getValue(e, i)} />
                                                            </td>
                                                            <td>
                                                                <input type="number" min={0} name="Pcs" className='form_input min_width_200 text-end' readOnly={!paymentRemaining || x.Labour} value={x.Pcs} onChange={(e) => getValue(e, i)} />
                                                                {
                                                                    tableError[i]?.includes('Pcs') ? <p className='error_message m-0 p-0'>Piece must be required</p> : <></>
                                                                }
                                                            </td>
                                                            <td>
                                                                <input type="number" min={0} name="Rate" value={x.Rate} readOnly={!paymentRemaining || x.Labour} className='form_input min_width_200 bg-light text-end' onChange={(e) => getValue(e, i)} />
                                                                {
                                                                    tableError[i]?.includes('Rate') ? <p className='error_message m-0 p-0'>Rate must be required</p> : <></>
                                                                }
                                                            </td>
                                                            <td><input type="number" min={0} name="Amount" value={x.Amount} className='form_input min_width_200 text-end' onChange={(e) => getValue(e, i)} /></td>
                                                            <td>
                                                                {
                                                                    paymentRemaining ?
                                                                        <span className='me-3 mt-2 d-inline-block'><RiDeleteBin6Line size={25} color='red' className='cursor_pointer' onClick={() => removeItem(i)} /></span>
                                                                        : <></>
                                                                }
                                                                {
                                                                    formRowsData?.length == i + 1 && paymentRemaining ?
                                                                        <button type='button' className='btn btn-warning py-1 mt-1' onClick={() => addNewRow()}>Add</button> :
                                                                        <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    })
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan={20} className='text-center'>There is no data.</td>
                                                        </tr>
                                                    </>
                                            }
                                            <tr className={formRowsData?.length > 0 ? 'fw-bold' : 'd-none'}>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='text-end'>{isGst?'TOTAL':'PAYABLE AMOUNT'}</td>
                                                <td className='text-end'>{roundValue(totalOrderData.Rate)}</td>
                                                <td className='text-end'>{roundValue(totalOrderData.Amount)}</td>
                                                <td></td>
                                            </tr>
                                                {
                                                    isGst ?
                                                    <>
                                                    
                                                    <tr className={formRowsData?.length > 0 ? 'fw-bold' : 'd-none'}>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-end'>CGST</td>
                                                        <td className='text-end'>{CGST ? CGST:AllCompanies[0].Cgst }%</td>
                                                        <td className='text-end'>{roundValue((totalOrderData.Amount) * ((CGST ? CGST:AllCompanies[0].Cgst)/100))}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className={formRowsData?.length > 0 ? 'fw-bold' : 'd-none'}>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-end'>SGST</td>
                                                        <td className='text-end'>{SGST ? SGST:AllCompanies[0].Sgst}%</td>
                                                        <td className='text-end'>{roundValue((totalOrderData.Amount) * ((CGST ? CGST:AllCompanies[0].Cgst)/100))}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className={formRowsData?.length > 0 ? 'fw-bold' : 'd-none'}>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-end'>PAYABLE AMOUNT</td>
                                                        <td className='text-end'></td>
                                                        {/* <td className='text-end'>{roundValue(Number(roundValue(totalOrderData.Amount)) +
                                                                                    Number(roundValue(totalOrderData.Amount) * (AllCompanies[0].Cgst / 100)) +
                                                                                    Number(roundValue(totalOrderData.Amount) * (AllCompanies[0].Sgst / 100)))}
                                                        </td> */}
                                                        <td className='text-end'>{roundValue(payableAmount)}</td>
                                                        <td></td>
                                                    </tr>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    isSelected ?? <></>
                                }
                                
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>

            {/* <Modal size='xl' show={show} fullscreen='md-down' onHide={handleClose} backdrop="static" >
                <Modal.Header className='border-0 p-0'>
                </Modal.Header>
                <Modal.Body className='pt-0 p-0'>
                    <CustomDataTable columns={columns} MobileColumns={MobileColumns} data={AllConfirmOrders} ExpandedComponent={ExpandedComponent} />
                </Modal.Body>
                <Modal.Footer className='py-1'>
                    <Button variant="outline-danger" className="fs-14px" onClick={handleClose}>Close</Button>
                </Modal.Footer>

            </Modal> */}

            <PaymentPopup state={{ show: paymentShow, setshow: setpaymentShow, BillNo, setBillNo }} />
        </>
    )
}

export default BillMaster