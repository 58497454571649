import { CUSTOMER_INFO } from "../Types/Type"

let initialState = {
    customerServiceInfo: []
}

export const customerInfoAboutServiceReducer = (state = initialState , action) => {
    switch(action.type){
        case CUSTOMER_INFO : 
            return {
                customerServiceInfo : action.data
            };
        default : 
            return state;
    }
}